@import "../../Styles/Base/Helpers";

$wizard-settings: (
    Theme: (
        Base: (
            Background: palette(Dark, Lighter-70),
            Color: palette(Dark, Lighter-70)
        ),
        Primary: (
            Background: palette(Primary, Base),
            Color: palette(Dark, Base)
        ),
        Dark : (
            Background: palette(Dark, Base),
            Color: palette(Dark, Base)
        )
    )
);

.c-Wizard {
    position: relative;

    $wizard: &;

    $base-theme: map-deep-get($wizard-settings, Theme, Base);
    --bd-WizardStep-Background: #{map-get($base-theme, Background)};
    --bd-WizardStep-Color: #{map-get($base-theme, Color)};

    @each $theme, $colors in map-get($wizard-settings, Theme) {
        @if $theme != Base {
            &--#{$theme} {
                #{$wizard}__Step {
                    &--Active {
                        --bd-WizardStep-Background: #{map-get($colors, Background)};
                        --bd-WizardStep-Color: #{map-get($colors, Color)};
                    }
                }
            }
        } 
    }

    &__Nav {
        align-items: flex-start;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(Surface) {
            align-items: flex-end;
            flex-direction: row;
        }
    }

    &__Step {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
        justify-content: center;
        width: 100%;

        &--Disabled {
            #{$wizard}__Label {
                cursor: not-allowed;
                pointer-events: none;
            }
        }

        @include media-breakpoint-up(Surface) {
            &:not(:last-child) {
                margin-right: 2rem;
            }
        }
    }

    &__Label {
        cursor: pointer;
        display: flex;
        flex: 1;
        flex-direction: column;
        outline: 0;
    }

    &__Title {
        color: var(--bd-WizardStep-Color);
        font-weight: 700;
        padding-bottom: 1rem;
        padding-top: 1rem;
        position: relative;
        transition: color 0.15s ease;
        width: 100%;
    }

    &__Bar {
        border-radius: 2px;
        height: 4px;
        width: 100%;
        background-color: var(--bd-WizardStep-Background);
        position: relative;
        transition: background-color 0.15s ease;
    }

    &__Content {
        position: relative;
    }

    &__StepContent {
        position: relative;
    }

    &__Actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        > * {            
            margin-top: .5rem;            
        }

        @include media-breakpoint-up(Surface) {
            > * {            
                margin-left: .5rem;
                margin-top: 0;;         
            }
        }
    }
}