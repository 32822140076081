﻿@use "sass:math";
@import "../../Styles/Base/Helpers";

$gutter-settings: $spacers;

$container-settings: (
    Widths: $container-widths,
    XPadding: 1rem
);

$grid-settings: (
    Breakpoints: $grid-breakpoints,
    Columns: 12,
    GutterWidth: 1.5rem,
    RowColumns: 6,
    IncludeColumnBoxSizing: true
);

/*#region Mixins */

/*#region Container */

@mixin make-container($padding-x: map-get($container-settings, XPadding)) {
    padding-right: $padding-x;
    padding-left: $padding-x;
    margin-right: auto;
    margin-left: auto;
}

/*#endregion*/

/*#region Grid System */

@mixin make-row($gutter: map-get($grid-settings, GutterWidth)) {
    --bd-gutter-x: #{$gutter};
    --bd-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bd-gutter-y) * -1); // stylelint-disable-line function-blacklist
    margin-right: calc(var(--bd-gutter-x) / -2); // stylelint-disable-line function-blacklist
    margin-left: calc(var(--bd-gutter-x) / -2); // stylelint-disable-line function-blacklist
}

@mixin make-col-ready($gutter: map-get($grid-settings, GutterWidth)) {
    // Add box sizing if only the grid is loaded
    box-sizing: if(map-get($grid-settings, IncludeColumnBoxSizing), border-box, null);
    // Prevent columns from becoming too narrow when at smaller grid tiers by
    // always setting `width: 100%;`. This works because we set the width
    // later on to override this initial width.
    flex-shrink: 0;
    width: 100%;
    max-width: 100%; // Prevent `.col-auto`, `.col` (& responsive variants) from breaking out the grid
    padding-right: calc(var(--bd-gutter-x) / 2); // stylelint-disable-line function-blacklist
    padding-left: calc(var(--bd-gutter-x) / 2); // stylelint-disable-line function-blacklist
    margin-top: var(--bd-gutter-y);
}

@mixin make-col($size, $columns: map-get($grid-settings, Columns)) {
    flex: 0 0 auto;
    width: percentage(math.div($size, $columns));
}

@mixin make-col-auto() {
    flex: 0 0 auto;
    width: auto;
}

@mixin make-col-offset($size, $columns: map-get($grid-settings, Columns)) {
    $num: math.div($size, $columns);
    margin-left: if($num==0, 0, percentage($num));
}

// Row columns
//
// Specify on a parent element(e.g., .row) to force immediate children into NN
// numberof columns. Supports wrapping to new lines, but does not do a Masonry
// style grid.
@mixin row-cols($count) {
    &>* {
        flex: 0 0 auto;
        width: calc(100% / $count);
    }
}

// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

@mixin make-grid-columns($columns: map-get($grid-settings, Columns), $gutter: map-get($grid-settings, GutterWidth), $breakpoints: map-get($grid-settings, Breakpoints)) {
    @each $breakpoint in map-keys($breakpoints) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);
        $separaror: if($infix=="", "--", "-");

        @include media-breakpoint-up($breakpoint, $breakpoints) {

            // Provide basic `.l-Col--{bp}` classes for equal-width flexbox columns
            .l-Col#{$infix} {
                flex: 1 0 0%;
            }

            .l-Row#{$infix}#{$separaror}Cols-Auto>* {
                @include make-col-auto();
            }

            @if map-get($grid-settings, RowColumns)>0 {
                @for $i from 1 through map-get($grid-settings, RowColumns) {
                    .l-Row#{$infix}#{$separaror}Cols-#{$i} {
                        @include row-cols($i);
                    }
                }
            }

            .l-Col#{$infix}#{$separaror}Auto {
                @include make-col-auto();
            }

            @if $columns>0 {
                @for $i from 1 through $columns {
                    .l-Col#{$infix}#{$separaror}#{$i} {
                        @include make-col($i, $columns);
                    }
                }

                // `$columns - 1` because offsetting by the width of an entire row isn't possible
                @for $i from 0 through ($columns - 1) {
                    @if not ($infix==""and $i==0) {

                        // Avoid emitting useless .offset-0
                        .l-Col#{$infix}#{$separaror}Offset-#{$i} {
                            @include make-col-offset($i, $columns);
                        }
                    }
                }
            }

            // Gutters
            //
            // Make use of `.u-Gutters--*`, `.u-Gutters-X--*` or `.u-Gutters-Y--*` utilities to change spacing between the columns.
            @each $key,
            $value in $gutter-settings {

                .u-Gutters#{$infix}#{$separaror}#{$key},
                .u-Gutters-X#{$infix}#{$separaror}#{$key} {
                    --bd-gutter-x: #{$value};
                }

                .u-Gutters#{$infix}#{$separaror}#{$key},
                .u-Gutters-Y#{$infix}#{$separaror}#{$key} {
                    --bd-gutter-y: #{$value};
                }
            }
        }
    }
}

/*#endregion*/

/*#endregion*/


.l-Container {
    &:not(.l-Container--Custom) {
        @include make-container();
    }

    &--Fluid {
        width: 100%;
    }
}

@each $breakpoint,
$container-max-width in map-get($container-settings, Widths) {
    .l-Container--#{$breakpoint} {
        @extend .l-Container--Fluid;
    }

    @include media-breakpoint-up($breakpoint, map-get($grid-settings, Breakpoints)) {
        $infix: breakpoint-infix($breakpoint, map-get($grid-settings, Breakpoints));

        @if not ($infix=="") {
            .l-Container#{$infix} {
                max-width: $container-max-width;
            }
        }
    }
}

.l-Row {
    @include make-row();

    >* {
        @include make-col-ready();
    }
}

@include make-grid-columns();