@import "../../Styles/Base/Helpers";

$helpermessage-settings: (
    Color: palette(Dark, Lighter-30),
    FontSize: .625rem,
    Validation: (
        Invalid: palette(Danger),
        Valid: palette(Success)
    ),
);

.o-HelperMessage {
    color: var(--bd-HelperMessage-Color);
    display: block;
    font-size: map-get($helpermessage-settings, FontSize);
    margin-top: .25rem;

    --bd-HelperMessage-Color: #{map-get($helpermessage-settings, Color)};

    @each $validation, $color in map-get($helpermessage-settings, Validation) {
        &--#{$validation} {
            --bd-HelperMessage-Color:  #{$color};
        }
    }
}