@use "sass:math";
@import "../../Styles/Base/Helpers";

$hamburger-theme: (
    Primary: (
        Color: palette(Dark, Lighter-20)
    ),
    Secondary: (
        Color: palette(Neutral, White)
    )
);

#{$hamburger-class} {
    --bd-Hamburger-Color: #{$hamburger-layer-color};
    
    align-items: center;
    display: inline-flex;
    color: var(--bd-Hamburger-Color);
    cursor: pointer;
    justify-content: center;
    padding: $hamburger-padding-y $hamburger-padding-x;
    transition-property: opacity, filter;
    transition-duration: $hamburger-hover-transition-duration;
    transition-timing-function: $hamburger-hover-transition-timing-function;
    // Normalize (<button>)
    font: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    @each $theme, $colors in $hamburger-theme{
        &--#{$theme} {
            --bd-Hamburger-Color: #{map-get($colors, Color)};
        }
    }

    &--Slider {
        &#{$hamburger-class}--Active,
        &:hover:not(#{$hamburger-class}--Active) {
            #{$hamburger-class}__Inner {
                &:after {
                    width: $hamburger-layer-width;
                }
            }
        }

        #{$hamburger-class}__Inner {
            &:after {
                transition: width .3s ease-out;
                width: math.div($hamburger-layer-width, 2);
            }
        }
    }

    &#{$hamburger-class}--Active {
        #{$hamburger-class}__Label {

            &::after {
                content: attr(data-on-text);
            }
        }
    }

    &:focus {
        outline: none;
    }

    &:hover {
        @if $hamburger-hover-use-filter == true {
            filter: $hamburger-hover-filter;
        }
        @else {
            opacity: $hamburger-hover-opacity;
        }
    }    

    &__Box {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
        display: inline-flex;
        position: relative;

        + #{$hamburger-class}__Label {
            margin-left: $hamburger-menu-font-size;
        }
    }

    &__Inner {
        display: inline-flex;
        top: 50%;
        margin-top: math.div($hamburger-layer-height, -2);

        &,
        &::before,
        &::after {
            width: $hamburger-layer-width;
            height: $hamburger-layer-height;
            background-color: currentColor;
            border-radius: $hamburger-layer-border-radius;
            position: absolute;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease;
        }

        &::before,
        &::after {
            content: "";
            display: block;
        }

        &::before {
            top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
        }

        &::after {
            bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
        }
    }

    &__Label {
        align-items: center;
        color: currentColor;
        display: none;
        font-size: $hamburger-menu-font-size;
        font-weight: $hambrger-menu-font-weight;
        position: relative;
        text-transform: uppercase;

        &::after {
            content: attr(data-off-text);
            position: relative;
        }

        + #{$hamburger-class}__Box {
            margin-left: $hamburger-menu-font-size;
        }

        @include media-breakpoint-up(Surface) {
            display: inline-flex;
        }
    }
}