﻿@import "../../Styles/Base/Helpers";

$panel-settings: ( Default: ( Color: palette(Dark, Lighter-30) ), Active: ( Color: palette(Neutral, White) ), Disabled: ( Background: palette(Dark, Lighter-80), Color: palette(Dark, Lighter-30) ), Theme: ( Primary: ( Main: palette(Dark, Lighter-75), Active: palette(Primary, Base), ), Secondary: ( Main: palette(Dark, Lighter-75), Active: palette(Danger, Base), ), ), Skin: ( Bordered: palette(Dark, Lighter-75) ) );

.c-PanelGroup {
    display: flex;
    flex-direction: column;

    > .o-Panel {
        &:not(:last-child) {
            margin-bottom: .5rem;
        }
    }
}

.o-Panel {
    border-radius: var(--bd-Panel-BorderRadius);
    overflow: hidden;
    --bd-Panel-BorderRadius: var(--bd-Card-Radius);

    $panel: &;

    &:hover:not(#{$panel}--Disabled) {
        #{$panel}__Header {
            opacity: .8;
        }
    }

    @each $theme, $colors in map-get($panel-settings, Theme) {
        &--#{$theme} {
            --bd-Panel-Header-Background: #{map-get($colors, Main)};
            --bd-Panel-Header-Color: #{map-deep-get($panel-settings, Default, Color)};

            &#{$panel}--Active {
                --bd-Panel-Header-Background: #{map-get($colors, Active)};
                --bd-Panel-Header-Color: #{map-deep-get($panel-settings, Active, Color)};
            }
        }
    }

    &--Active {
        #{$panel}__Arrow {
            color: map-deep-get($panel-settings, Active, Color);
            transform: rotate(-90deg);
        }

        #{$panel}__Content {
            max-height: none;
            padding-bottom: 1.5rem;
            padding-top: 1.5rem;

            @include media-breakpoint-up(Surface) {
                padding-bottom: 4rem;
                padding-top: 4rem;
            }
        }
    }

    @media print {
        &--Print {
            #{$panel}__Arrow {
                color: map-deep-get($panel-settings, Active, Color);
                transform: rotate(-90deg);
            }

            #{$panel}__Header {
                background-color: transparent;
                color: #{map-deep-get($panel-settings, Default, Color)};
            }

            #{$panel}__Content {
                max-height: none;
                padding-bottom: 0;
                padding-top: 0;
            }
        }
    }

    &--Disabled {
        #{$panel}__Header {
            cursor: default;
            --bd-Panel-Header-Background: map-deep-get($panel-settings, Disabled, Background);
        }
    }

    &--Bordered {
        &#{$panel}--Active {
            #{$panel}__Content {
                border-bottom-width: 1px;
                border-left-width: 1px;
                border-right-width: 1px;
                border-top-width: 0px;
            }
        }

        #{$panel}__Content {
            border-color: #{map-deep-get($panel-settings, Skin, Bordered)};
            border-style: solid;
            border-width: 0px;
        }
    }

    &__Arrow {
        color: currentColor;
        transition: all .3s ease-out;
    }

    &__Content {
        background: palette(Neutral, White);
        border-bottom-left-radius: var(--bd-Panel-BorderRadius);
        border-bottom-right-radius: var(--bd-Panel-BorderRadius);
        max-height: 0;
        overflow: hidden;
        padding-left: 1rem;
        padding-right: 1rem;
        transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);

        @include media-breakpoint-up(Surface) {
            padding-left: 4rem;
            padding-right: 4rem;
        }
    }

    &__Header {
        background: var(--bd-Panel-Header-Background);
        color: var(--bd-Panel-Header-Color);
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1rem 1rem 1rem 1rem;
        transition: all .3s ease-out;

        @include media-breakpoint-up(Surface) {
            padding: 1.2rem 2rem 1.2rem 2rem;
        }
    }

    &__Title {
        align-items: center;
        display: flex;
    }

    &__Icon {
        color: currentColor;
        margin-right: .5rem;
    }

    &__Label {
        color: currentColor !important;
    }
}
