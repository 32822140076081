@import "../../Styles/Base/Helpers";

$hero-settings: (
    Size: (
        Collapsed: 0, 
        Medium: 50vh, 
        Large: 75vh, 
        Fullheight: 100vh, 
        FitParent: 100% 
    ),    
);

.l-Hero {
    overflow: hidden;
    position: relative;

    $hero: &;

    @each $size, $height in map-get($hero-settings, Size) {
        &--#{$size} {
            height: #{$height};
        }
    }

    &--Shaded {
        #{$hero}__Background {
            &:after {
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .1) 75%, rgba(0, 0, 0, .5) 100%);
                content: '';
                display: block;
                pointer-events: none;
                @include position(absolute, 0, 0, 0, 0);
                @include z-index(Inside);
            }
        }
    }

    &--BottomTransparent {
        &:before {
            content: '';
            z-index: 1;
            width: 100%;
            height: 25%;
            position: absolute;
            left: 0;
            bottom: 0;
            background: linear-gradient(to bottom, transparent 0, var(--bd-Neutral-Background) 100%);
        }
    }

    &__Background {
        height: 100%;
        user-select: none;
        width: 100%;
        @include position(absolute, $top: 0, $left: 0);
        @include z-index(Neutral);
    }

    &__Foreground {
        display: flex;
        height: 100%;
        width: 100%;
        @include position(relative);
        @include z-index(Inside);

        &--Start {
            align-items: flex-start;
        }

        &--Middle {
            align-items: center;
        }

        &--End {
            align-items: flex-end;
        }
    }

    &__Caption {
        position: relative;
        @include z-index(Inside);
    }
}