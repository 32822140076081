/*#region Position */

@mixin position($position: absolute, $top: null, $right: null, $bottom: null, $left: null) {
    @if $bottom {
        bottom: $bottom;
    }

    @if $left {
        left: $left;
    }

    position: $position;

    @if $right {
        right: $right;
    }

    @if $top {
        top: $top;
    }
}

@mixin centerer($horizontal: true, $vertical: true) {
    @if ($horizontal and $vertical) {
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
    }

    @else if ($horizontal) {
        left: 50%;
        transform: translate3d(-50%, 0, 0);
    }

    @else if ($vertical) {
        top: 50%;
        transform: translate3d(0, -50%, 0);
    }
}

/*#endregion*/