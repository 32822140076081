﻿@import "../../Styles/Base/Helpers";

$tooltip-settings: (
    Theme: (
        Primary: (
            Background: palette(Primary),
            Color: palette(Neutral, White),
        ),
    ),
    Arrow: (
        Height: .5rem,
        Width: 1rem
    ),
    BorderRadius: .5rem
);

@keyframes bouncingbottom {
    0%, 
    20%, 
    50%, 
    80%, 
    100% {
        margin-top: calc(var(--bd-Tooltip-Arrow-Height) + 9px);
    }
    
    40% {
        margin-top: calc(var(--bd-Tooltip-Arrow-Height) + 29px);
    }

    60% {
        margin-top: calc(var(--bd-Tooltip-Arrow-Height) + 14px);
    }
}

@keyframes bouncingleft {
    0%, 
    20%, 
    50%, 
    80%, 
    100% {
        margin-right: calc(var(--bd-Tooltip-Arrow-Height) + 9px);
    }
    
    40% {
        margin-right: calc(var(--bd-Tooltip-Arrow-Height) + 29px);
    }

    60% {
        margin-right: calc(var(--bd-Tooltip-Arrow-Height) + 14px);
    }
}

.l-TooltipSurface {
    display: flex;
}

.o-Tooltip {
    position: absolute;
    left: 0;
    opacity: 0;
    top: 0;
    visibility: hidden;
    @include z-index(Tooltip);

    --bd-Tooltip-Arrow-Height: #{map-deep-get($tooltip-settings, Arrow, Height)};
    --bd-Tooltip-Arrow-Width: #{map-deep-get($tooltip-settings, Arrow, Width)};
    --bd-Tooltip-BorderRadius: #{map-get($tooltip-settings, BorderRadius)};

    @each $theme, $colors in map-get($tooltip-settings, Theme) {
        &--#{$theme} {
            --bd-Tooltip-Background: #{map-get($colors, Background)};
            --bd-Tooltip-Color: #{map-get($colors, Color)};
        }
    }

    $tooltip: &;

    &--Active {
        opacity: 1;
        visibility: visible;
    }

    &[data-popper-placement^="top"] {

    }
    
    &[data-popper-placement^="right"] {

    }
      
    &[data-popper-placement^="bottom"] {
        &#{$tooltip}--Bouncing {
            #{$tooltip}__Dialog {
                animation: bouncingbottom 2s 3;
            }
        }

        #{$tooltip}__Dialog {
            margin-top: calc(var(--bd-Tooltip-Arrow-Height) + 9px);
        }
        
        #{$tooltip}__Arrow {
            top: calc(-1 * var(--bd-Tooltip-Arrow-Height));
          
            &::before {
                top: 0;
                border-width: 0 calc(var(--bd-Tooltip-Arrow-Width) / 2) var(--bd-Tooltip-Arrow-Height) calc(var(--bd-Tooltip-Arrow-Width) / 2);
                border-bottom-color: var(--bd-Tooltip-Background);
              }
        }
    }
      
    &[data-popper-placement^="left"] {
        &#{$tooltip}--Bouncing {
            #{$tooltip}__Dialog {
                animation: bouncingleft 2s 3;
            }
        }

        #{$tooltip}__Dialog {
            margin-right: calc(var(--bd-Tooltip-Arrow-Height) + 9px);
        }
        
        #{$tooltip}__Arrow {
            height: var(--bd-Tooltip-Arrow-Width);
            right: calc(-1 * var(--bd-Tooltip-Arrow-Height));
            width: var(--bd-Tooltip-Arrow-Height);

            &::before {
                left: -1px;
                border-width: calc(var(--bd-Tooltip-Arrow-Width) / 2) 0 calc(var(--bd-Tooltip-Arrow-Width) / 2) var(--bd-Tooltip-Arrow-Height);
                border-left-color: var(--bd-Tooltip-Background);
            }
        }
    }

    &__Dialog {
        background-color: var(--bd-Tooltip-Background);
        background-clip: padding-box;
        border-radius: var(--bd-Tooltip-BorderRadius);
        color: var(--bd-Tooltip-Color);
        display: block;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-break: auto;
        line-height: 1.6;
        max-width: 215px;
        opacity: 0;
        position: relative;
        text-align: center;
        white-space: normal;
        word-break: normal;
        word-wrap: break-word;
    }

    &__Arrow {
        display: block;
        height: var(--bd-Tooltip-Arrow-Height);
        position: absolute;
        width: var(--bd-Tooltip-Arrow-Width);
        
        &::before {
            border-color: transparent;
            border-style: solid;
            content: "";
            display: block;
            position: absolute;
        }
    }

    &__Close {
        cursor: pointer;
        position: absolute;
        right: .5rem;
        top: .5rem;
    }

    &__Content {
        padding: 1rem 2rem;
    }
}