@use "sass:math";

@mixin aspect-ratio($x,$y, $pseudo: true) {
    $padding: unquote( math.div($y, $x) * 100 + '%' );

    @if $pseudo {
        &:before {
            content: '';
            display: block;
            padding-top: $padding;
            position: relative;
            width: 100%;
        }
    }
    @else {
        padding-top: $padding;
    }
}