﻿@import "../../Styles/Base/Helpers";

$modal-settings: (
    Size: (
        Small: 300px,
        Medium: 500px,
        Large: 800px,
        ExtraLarge: 1140px
    ),
    Skin: (
        Light: (
            HeaderBackground: palette(Neutral, White),
            HeaderColor: palette(Dark, Lighter-30),
            BodyBackground: palette(Neutral, White),
            FooterBackground: palette(Neutral, White)
        ),
        Solid: (
            HeaderBackground: palette(Primary),
            HeaderColor: palette(Neutral, White),
            BodyBackground: palette(Neutral, White),
            FooterBackground: palette(Neutral, White)
        )
    ),
    BackdropBackground: palette(Dark),
    Dialog: (
        BorderRadius: var(--bd-Modal-Radius)
    ),
    DialogMargin: (
        Default: .5rem,
        FromPhablet: 5rem auto,
    )
);

.l-ModalSurface {
    &__Backdrop {
        background-color: map-get($modal-settings, BackdropBackground);
        opacity: .5;
        @include position("fixed", $top: 0, $right: 0, $bottom: 0,$left: 0);
        @include z-index(ModalBackdrop)
    }
}

.c-Modal {
    height: 100%;
    opacity: 0;
    outline: 0;
    overflow: hidden;
    transition: opacity .15s linear;
    visibility: hidden;
    width: 100%;
    @include position("fixed", $left: 0, $top: 0);
    @include z-index(Modal);

    $modal: &;

    @each $skin, $properties in map-get($modal-settings, Skin) {
        &--#{$skin} {
            --bd-Modal-Header-Background: #{map-get($properties, HeaderBackground)};
            --bd-Modal-Body-Background: #{map-get($properties, BodyBackground)};
            --bd-Modal-Footer-Background: #{map-get($properties, FooterBackground)};
            --bd-Modal-Header-Color: #{map-get($properties, HeaderColor)};

            #{$modal}__Header {
                @if $skin == Light {
                    padding: 3rem 2rem .5rem;

                    #{$modal}__Divider {
                        @include position(absolute, $left: 2rem, $bottom: 0);
                    }

                    @include media-breakpoint-up(Surface) {
                        padding: 3rem 4.5rem .5rem;

                        #{$modal}__Divider {
                            left: 4.5rem;
                        }
                    }
                }
                @else if $skin == Solid {
                    padding: 1rem 1.5rem;
                }
            }

            #{$modal}__Body {
                @if $skin == Light {
                    padding: 1.5rem 2rem 3rem 2rem;

                    @include media-breakpoint-up(Surface) {
                        padding: 1.5rem 4.5rem 3rem 4.5rem;
                    }
                }
                @else if $skin == Solid {
                    padding: 2rem 1.5rem;
                }
            }

            #{$modal}__Footer {
                @if $skin == Light {
                    padding: 3rem 2rem 3rem;

                    #{$modal}__Divider {
                        @include position(absolute, $left: 2rem, $top: 0);
                    }

                    @include media-breakpoint-up(Surface) {
                        padding: 3rem 4.5rem 3rem;

                        #{$modal}__Divider {
                            left: 4.5rem;
                        }
                    }
                }
                @else if $skin == Solid {
                    padding: 3rem 2rem 3rem;

                    #{$modal}__Divider {
                        @include position(absolute, $left: 2rem, $top: 0);
                    }

                    @include media-breakpoint-up(Surface) {
                        padding: 3rem 4.5rem 3rem;

                        #{$modal}__Divider {
                            left: 4.5rem;
                        }
                    }
                }
            }

            @if $skin == Light {

                #{$modal}__Divider {
                    padding: 0;
                    min-width: 0;
                    width: calc(100% - 4rem);

                    @include media-breakpoint-up(Surface) {
                        width: calc(100% - 9rem);
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(Phablet) {
        &--Small {
            --bd-Modal-Width: #{map-deep-get($modal-settings, Size, Small)};

            &#{$modal}--Sticky {
                max-width: var(--bd-Modal-Width);
            }

            #{$modal}__Dialog {
                max-width: var(--bd-Modal-Width);
            }
        }

        &--Medium,
        &--Large,
        &--ExtraLarge {
            --bd-Modal-Width: #{map-deep-get($modal-settings, Size, Medium)};

            &#{$modal}--Sticky {
                max-width: var(--bd-Modal-Width);
            }

            #{$modal}__Dialog {
                max-width: var(--bd-Modal-Width);
            }
        }

        #{$modal}__Dialog {
            margin: map-deep-get($modal-settings, DialogMargin, FromPhablet);
        }
    }

    @include media-breakpoint-up(Surface) {
        &--Large,
        &--ExtraLarge {
            --bd-Modal-Width: #{map-deep-get($modal-settings, Size, Large)};

            &#{$modal}--Sticky {
                max-width: var(--bd-Modal-Width);
            }

            #{$modal}__Dialog {
                max-width: var(--bd-Modal-Width);
            }
        }
    }

    @include media-breakpoint-up(Laptop) {
        &--ExtraLarge {
            --bd-Modal-Width: #{map-deep-get($modal-settings, Size, ExtraLarge)};

            &#{$modal}--Sticky {
                max-width: var(--bd-Modal-Width);
            }

            #{$modal}__Dialog {
                max-width: var(--bd-Modal-Width);
            }
        }
    }

    &--Active {
        opacity: 1;
        overflow-x: hidden;
        overflow-y: auto;
        visibility: visible;

        #{$modal}__Dialog {
            transform: none;
        }
    }

    &--Sticky {
        border-radius: map-deep-get($modal-settings, Dialog, BorderRadius);
        box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
        height: auto;
        left: auto;
        margin: map-deep-get($modal-settings, DialogMargin, Default);
        margin-right: var(--bd-Body-Offset);
        padding-right: 0;
        top: auto;
        width: calc(100% - #{map-deep-get($modal-settings, DialogMargin, Default)} * 2);
        @include z-index(Fixed);

        &#{$modal}--BottomRight {
            bottom: 1.5rem;

            @include media-breakpoint-up(Phablet) {
                right: calc(1.5rem + #{map-deep-get($modal-settings, DialogMargin, Default)});
            }
        }

        #{$modal}__Dialog {
            margin: 0;
            position: static;
            transform: none;
        }
    }

    &__Depth {
        @include position("fixed", $left: 0, $top: 0, $bottom: 0, $right: var(--bd-Body-Offset));
    }

    &__Dialog {
        margin: map-deep-get($modal-settings, DialogMargin, Default);
        pointer-events: none;
        position: relative;
        transform: translate(0, -50px);
        transition: transform .3s ease-out;
        width: auto;

        &:focus {
            outline: 0;
        }
    }

    &__Content {
        background-clip: padding-box;
        border-radius: map-deep-get($modal-settings, Dialog, BorderRadius);
        display: flex;
        flex-direction: column;
        outline: 0;
        overflow: hidden;
        pointer-events: auto;
        position: relative;
        width: 100%;
    }

    &__Header {
        background-color: var(--bd-Modal-Header-Background);
        color: var(--bd-Modal-Header-Color);
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        position: relative;

        #{$modal}__Label {
            color: currentColor;
            align-items: center;
            display: flex;
            flex-basis: 85%;
            margin: .5rem;
            margin-left: 0;

            > * {
                &:not(:first-child) {
                    margin-left: .5rem;
                }
            }
        }

        #{$modal}__Icon {
            color: currentColor;
        }

        #{$modal}__Title {
            color: currentColor;
        }

        #{$modal}__Subtitle {
            color: currentColor;
            font-size: .9;
            font-weight: 400;
        }

        #{$modal}__Close {
            cursor: pointer;
            fill: currentColor;
            margin: .5rem 0;
            opacity: 1;
            transition: opacity 0.15s ease;

            &:hover {
                opacity: .8;
            }
        }
    }

    &__Body {
        background-color: var(--bd-Modal-Body-Background);
    }

    &__Footer {
        background-color: var(--bd-Modal-Footer-Background);
        display: flex;
        flex-wrap: wrap;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        position: relative;

        > * {
            margin: calc(.1rem / 2);
        }
    }
}

.u-ScrollbarMeasure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }