@import "../../Styles/Base/Helpers";

$textfield-settings: (
    Validation: (
        Invalid: palette(Danger),
        Valid: palette(Success)
    ),
    Field: (
        Background: palette(Neutral, White),
        Color: palette(Dark, Lighter-30),
        BorderColor: palette(Dark, Lighter-75),
        BorderRadius: .5rem,
        FontSize: .75rem,
        Outline: palette(Dark),
    ),
    State: (
        Disabled: (
            Cursor: not-allowed,
            Color: palette(Dark, Lighter-50),
            Background: palette(Dark, Lighter-80),
        ),
        ReadOnly: (
            Cursor: default
        )        
    )
);

.o-TextField {
    align-items: center;
    background: var(--bd-TextField-Background);
    border: .3125rem solid var(--bd-TextField-BorderColor);
    border-radius: var(--bd-TextField-BorderRadius);
    display: flex;
    outline: 0;
    position: relative;

    --bd-TextField-Background: #{map-deep-get($textfield-settings, Field, Background)};
    --bd-TextField-BorderColor: #{map-deep-get($textfield-settings, Field, BorderColor)};
    --bd-TextField-Color: #{map-deep-get($textfield-settings, Field, Color)};
    --bd-TextField-Outline: #{map-deep-get($textfield-settings, Field, Outline)};
    --bd-TextField-FontSize: #{map-deep-get($textfield-settings, Field, FontSize)};
    --bd-TextField-BorderRadius: #{map-deep-get($textfield-settings, Field, BorderRadius)};

    $textfield: &;

    @each $validation, $color in map-get($textfield-settings, Validation) {
        &--#{$validation} {
            @if $validation == Valid {
                &#{$textfield}--Modified {
                    --bd-TextField-Outline: #{$color};
                    --bd-TextField-ValidationColor: #{$color};
                     box-shadow: 0 0 0 1px var(--bd-TextField-ValidationColor);
                }
            } @else {
                --bd-TextField-Outline: #{$color};
                --bd-TextField-ValidationColor: #{$color};
                box-shadow: 0 0 0 1px var(--bd-TextField-ValidationColor);
            }
        }
    }

    @each $state, $properties in map-get($textfield-settings, State) {
        &--#{$state} {
            @if $state == Disabled {
                --bd-TextField-Background: #{map-get($properties, Background)};
                --bd-TextField-Color: #{map-get($properties, Color)};
            }

            #{$textfield}__Input,
            #{$textfield}__Icon--Action {
                cursor: map-get($properties, Cursor);
                pointer-events: none;
            }
        }
    }

    &--Focused {
        box-shadow: 0 0 0 1px var(--bd-TextField-Outline);
    }

    &--Hidden {
        display: none;
    }

    &--TextArea {
        #{$textfield}__Input {
            min-height: 5rem;
            max-height: 18rem;
            padding-right: 3.5rem;
            resize: vertical;
        }

        #{$textfield}__Addon {
            &--Right {
                position: absolute;
                right: 0;
            }
        }
    }

    &__Input {
        background: transparent;
        border: 0;
        color: var(--bd-TextField-Color);
        font-size: var(--bd-TextField-FontSize);
        outline: 0;
        padding-bottom: .8rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        padding-top: .8rem;
        width: 100%;
    }

    &__Addon {
        align-items: center;
        background: transparent;
        display: flex;
        padding-bottom: .8rem;
        padding-top: .8rem;

        &--Left {
            padding-left: 1.25rem;
        }

        &--Right {
            padding-right: 1.25rem;
        }

        > #{$textfield}__Icon {
            &:not(:last-child) {
                margin-right: 1.25rem;
            }
        }
    }

    &__Icon {
        color: var(--bd-TextField-Color);

        &--Validation {
            color: var(--bd-TextField-ValidationColor);
        }

        &--Action {
            cursor: pointer;
            transition: opacity .15s ease;

            &:hover {
                opacity: .8;
            }

            &:active {
                opacity: .6;
            }
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px var(--bd-TextField-Background) inset;
}