@import "../../Styles/Base/Helpers";

:root {
    @each $theme, $variants in $color-palette {
        @each $variant, $value in $variants {
            --bd-#{$theme}-#{"" + $variant}: #{$value};
        }
    }
    @each $breakpoint, $value in $grid-breakpoints {
        --bd-Breakpoint-#{$breakpoint}: #{$value};
    }
    --bd-Navbar-Height: #{$navbar-height};
    --bd-Card-Radius: #{$card-border-radius};
    --bd-Input-Radius: #{$input-border-radius};
    --bd-Modal-Radius: #{$modal-border-radius};
    --bd-Widget-Radius: #{$widget-border-radius};
}

@at-root {
    @include json-encode($grid-breakpoints);

    html {
        overflow-y: scroll;
    }

    body {
        touch-action: manipulation;

        &:after {
            display: none;
            visibility: hidden;

            @each $breakpoint, $width in $grid-breakpoints {
                @if ($width == 0) {
                    content: '#{$breakpoint}';
                }
                @else {
                    @include media-breakpoint-up($breakpoint) {
                        content: '#{$breakpoint}';
                    }
                }
            }
        }
    }
}