﻿@import "../../Styles/Base/Helpers";

$select-settings: (
    Validation: (
        Invalid: palette(Danger),
        Valid: palette(Success)
    ),
    State: (
        Disabled: (
            Cursor: not-allowed,
            Color: palette(Dark, Lighter-50),
            Background: palette(Dark, Lighter-80),
        ),
    ),
    Field: (
        Outline: palette(Dark),
    ),
    List: (
        Background: palette(Neutral, White),
        MaxHeight: 25rem
    ),
    Option: (
        Height: 2.5rem,
        State: (
            Disabled: (
                Cursor: not-allowed,
            ),
            Active: (
                Background: palette(Dark, Lighter-85),
            )
        )
    )
);

.o-Select {
    outline: 0;
    position: relative;

    --bd-SelectList-Background: #{map-deep-get($select-settings, List, Background)};
    --bd-SelectList-Height: #{map-deep-get($select-settings, List, MaxHeight)};
    --bd-SelectOption-Height: #{map-deep-get($select-settings, Option, Height)};
    --bd-TextField-Outline: #{map-deep-get($select-settings, Field, Outline)};
    --bd-SelectList-BorderRadius: var(--bd-Widget-Radius);

    $select: &;

    @each $validation, $color in map-get($select-settings, Validation) {
        &--#{$validation} {
            @if $validation == Valid {
                &#{$select}--Modified {
                    --bd-TextField-Outline: #{$color};
                    --bd-TextField-ValidationColor: #{$color};

                    #{$select}__NativeControl {
                        box-shadow: 0 0 0 1px var(--bd-TextField-ValidationColor) !important;
                    }
                }
            } @else {
                --bd-TextField-Outline: #{$color};
                --bd-TextField-ValidationColor: #{$color};

                #{$select}__NativeControl {
                    box-shadow: 0 0 0 1px var(--bd-TextField-ValidationColor) !important;
                }
            }
        }
    }

    &--Focused {
        #{$select}__NativeControl {
            box-shadow: 0 0 0 1px var(--bd-TextField-Outline);
        }
    }

    &--Active {
        #{$select}__NativeControl {
            .o-TextField__Icon--Action {
                transform: rotate(180deg);
            }
        }
    }

    &--ReadOnly {
        .o-TextField__Input {
            cursor: pointer !important;
            user-select: none;
        }
    }

    &__List {
        background-color: var(--bd-SelectList-Background);
        box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
        border-radius: var(--bd-SelectList-BorderRadius);
        display: flex;
        flex-wrap: wrap;
        left: 0;
        margin-top: 5px;
        max-height: var(--bd-SelectList-Height);
        overflow-y: auto;
        position: absolute;
        width: 100%;
        @include z-index(Dropdown);
        @include scrollbar(4px, #{palette(Dark, Base)}, $background-color: transparent, $border-radius: 5px);
    }

    &__Option {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: var(--bd-SelectOption-Height);
        padding-left: 1rem;
        user-select: none;
        width: 100%;
    
        $options-states: map-deep-get($select-settings, Option, State);
        
        &:hover, 
        &--Hover {
            --bd-SelectOption-Background: #{map-deep-get($options-states, Active, Background)};

            &:not(#{$select}__Option--Disabled) {
                background-color: var(--bd-SelectOption-Background);
            }
        }
    
        &--Selected {
            font-weight: bold;
        }
    
        &--Disabled {
            cursor:  #{map-deep-get($options-states, Disabled, Cursor)};
            pointer-events: none;
        }
    }
}