﻿@import '../../Styles/Base/Helpers';

$typography-settings: (
    Colors: (
        Default: palette(Dark, Lighter-30),
        Selection: palette(Dark, Lighter-75)
    ),
    FontFamily: (
        Primary: #{'Mulish', sans-serif},
        Secondary: #{'Quattrocento', serif}
    ),
    FontWeight: (
        Regular: 400,
        SemiBold: 600,
        Bold: 700
    ),
    TextSize: (
        H1: 50px,
        H2: 34px,
        H3: 28px,
        H4: 24px,
        H5: 18px,
        H6: 16px,
        S1: 14px,
        S2: 12px,
        S3: 10px
    ),
    BaseFontSize: 16px,
    BaseLineHeight: 1.6
);

html {
    font-family: map-deep-get($typography-settings, FontFamily, Primary);
    font-size: map-get($typography-settings, BaseFontSize);
    line-height: map-get($typography-settings, BaseLineHeight);

    ::selection {
        background: map-deep-get($typography-settings, Colors, Default);
        color: map-deep-get($typography-settings, Colors, Selection);
    }
}

.o-Typography {
    color: map-deep-get($typography-settings, Colors, Default);

    &--InheritColor {
        color: currentColor;
    }

    @each $family, $value in map-get($typography-settings, FontFamily) {
        &--#{$family} {
            font-family: $value;
        }
    }

    @each $weight, $value in map-get($typography-settings, FontWeight) {
        &--#{$weight} {
            font-weight: $value;
        }
    }

    @each $size, $value in map-get($typography-settings, TextSize) {
        &--#{$size} {
            font-size: $value;

            @if ($size == H1 or $size == H2 or $size == H3 or $size == H4) {
                line-height: calc(#{map-get($typography-settings, BaseLineHeight)} * 0.8);
            }
        }
    }

    &--Muted {
        opacity: .5;
    }

    &--Paragraph {
        @each $size, $value in map-get($typography-settings, TextSize) {
            @if ($size != S1 or $size != S2 or $size != S3) {
                #{to-lower-case(#{$size})}:not(.o-Typography) {
                    font-size: $value;
                    line-height: calc(#{map-get($typography-settings, BaseLineHeight)} * 0.8);
                }
            }
        }

        a:not(.o-Button) {
            color: currentColor;
            font-weight: 700;
            text-decoration: none;
            user-select: auto;

            &:hover {
                opacity: .8;
                text-decoration: underline;
            }

            &:active {
                opacity: .4;
                text-decoration: underline;
            }
        }

        ol,
        ul {
            list-style: inside;
        }
    }

    &--PreLine {
        white-space: pre-line;
    }

    &--Text {
        > strong {
            color: palette(Primary);
        }
    }
}