@import "../../Styles/Base/Helpers";

$checkbox-settings: (
    Validation: (
        Invalid: palette(Danger),
        Valid: palette(Success)
    ),
    Checkbox: (
        Color: palette(Dark, Lighter-30),
        BorderColor: palette(Dark, Lighter-75),
        BorderRadius: var(--bd-Widget-Radius),
        CheckMarkColor: palette(Dark, Lighter-75),
        FontSize: .875rem,
        OuterSize: 1.5rem,
        CheckMarkHeight: .75rem,
        CheckMarkWidth: .375rem,
        CheckMarkMarginTop: -.125rem
    ),
    State: (
        Checked: (
            BorderColor: palette(Primary),
            CheckMarkColor: palette(Primary),
        ),
        Disabled: (
            Cursor: not-allowed,
            Color: palette(Dark, Lighter-50),
            BorderColor: palette(Dark, Lighter-80),
            CheckMarkColor: palette(Dark, Lighter-80),
        ),      
    )
);

.c-CheckboxList {
    display: flex;
    flex-direction: column;

    $checkboxlist: &;

    &:not(#{$checkboxlist}--GridLayout) {
        align-items: flex-start;
    }

    > .o-Checkbox {
        &:not(:last-child) {
            margin-bottom: .5rem;
        }
    }
}

.o-Checkbox {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin: 0;
    position: relative;
    transition: all 0.3s ease;
    --bd-Checkbox-BorderColor: #{map-deep-get($checkbox-settings, Checkbox, BorderColor)};
    --bd-Checkbox-BorderRadius: #{map-deep-get($checkbox-settings, Checkbox, BorderRadius)};
    --bd-Checkbox-Color: #{map-deep-get($checkbox-settings, Checkbox, Color)};
    --bd-Checkbox-FontSize: #{map-deep-get($checkbox-settings, Checkbox, FontSize)};
    --bd-Checkbox-OuterSize: #{map-deep-get($checkbox-settings, Checkbox, OuterSize)};
    --bd-Checkbox-CheckMarkColor: #{map-deep-get($checkbox-settings, Checkbox, CheckMarkColor)};
    --bd-Checkbox-CheckMarkHeight: #{map-deep-get($checkbox-settings, Checkbox, CheckMarkHeight)};
    --bd-Checkbox-CheckMarkWidth: #{map-deep-get($checkbox-settings, Checkbox, CheckMarkWidth)};
    --bd-Checkbox-CheckMarkMarginTop: #{map-deep-get($checkbox-settings, Checkbox, CheckMarkMarginTop)};

    $checkbox: &;

    @each $validation, $color in map-get($checkbox-settings, Validation) {
        &--#{$validation} {
            @if $validation == Valid {
                &#{$checkbox}--Modified {
                    #{$checkbox}__CheckMark {
                        --bd-Checkbox-BorderColor: #{$color};
                    }
                }
            } @else {
                #{$checkbox}__CheckMark {
                    --bd-Checkbox-BorderColor: #{$color};
                }
            }
        }
    }

    @each $state, $properties in map-get($checkbox-settings, State) {
        &--#{$state} {
            @if $state == Disabled {
                cursor: map-get($properties, Cursor);
                --bd-Checkbox-Color: #{map-get($properties, Color)};
            }

            #{$checkbox}__CheckMark {
                @if $state == Checked {
                    &:after {
                        opacity: 1;
                    }
                }

                --bd-Checkbox-BorderColor: #{map-get($properties, BorderColor)};
                --bd-Checkbox-CheckMarkColor: #{map-get($properties, CheckMarkColor)};
            }
        }
    }

    &:hover:not(#{$checkbox}--Disabled):not(#{$checkbox}--Valid):not(#{$checkbox}--Invalid) {
        $hover-state: map-deep-get($checkbox-settings, State, Checked);

        #{$checkbox}__CheckMark {
            --bd-Checkbox-BorderColor: #{map-get($hover-state, BorderColor)};
        }
    }

    &__NativeControl {
        opacity: 0;
        position: absolute;
        z-index: -1;
    }

    &__CheckMark {
        align-items: center;
        border: 2px solid var(--bd-Checkbox-BorderColor);
        border-radius: var(--bd-Checkbox-BorderRadius);
        display: flex;
        flex-shrink: 0;
        height: var(--bd-Checkbox-OuterSize);
        justify-content: center;
        margin-right: 0.75rem;
        transition: all .2s ease;
        width: var(--bd-Checkbox-OuterSize);

        &:after {
            border-color: var(--bd-Checkbox-CheckMarkColor);
            border-style: solid;
            border-width: 0 2px 2px 0;
            content: '';
            height: var(--bd-Checkbox-CheckMarkHeight);
            margin-top: var(--bd-Checkbox-CheckMarkMarginTop);
            transform: rotate(45deg);
            opacity: 0;
            transition: all .2s ease;
            width: var(--bd-Checkbox-CheckMarkWidth);
        }
    }

    &__Label {
        align-items: center;
        color: var(--bd-Checkbox-Color);
        display: inline-flex;
        flex-wrap: wrap;
        font-size: var(--bd-Checkbox-FontSize);
    }
}