﻿@import "../../Styles/Base/Helpers";

$navbar-settings: (
    Background: #{palette(Neutral, White)},
    Height: $navbar-height,
    Navigation-Color: #{palette(Neutral, White)},
);

.c-Navbar {
    align-items: center;
    background: map-get($navbar-settings, Background);
    display: flex;
    height: map-get($navbar-settings, Height);
    padding: 0 1rem;
    width: 100%;
    @include position($top: 0, $left: 0);
    @include z-index(Fixed);

    --bd-Navbar-Navigation-Background: var(--bd-Primary-Base);

    $navbar: &;

    &--Active {
        #{$navbar}__Navigation {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            opacity: 1;
            visibility: visible;
            z-index: 1;
        }
    }

    &--Transitioning {
        top: -#{map-get($navbar-settings, Height)};
    }

    &--Sticky {
        position: fixed;
        top: 0;
        transition: top .5s ease-out;
        width: calc(100% - var(--bd-Body-Offset, 0px));
    }

    &__Item {
        align-items: center;
        display: flex;
        flex: 1;
        height: 100%;
        font-size: 16px;
        line-height: 3;

        &--Start {
            justify-content: flex-start;
        }

        &--Middle {
            justify-content: space-around;
        }

        &--End {
            justify-content: flex-end;
        }
    }

    &__Link {
        color: inherit;
        text-decoration: none;
        transition: opacity .3s ease-out;

        &:hover {
            opacity: .8;
        }
    }

    &__Navigation {
        background: var(--bd-Navbar-Navigation-Background);
        height: calc((var(--vh, 1vh) * 100) - #{map-get($navbar-settings, Height)});
        opacity: 0;
        overflow-y: scroll;
        overflow-x: hidden;
        visibility: hidden;
        width: 100%;
        bottom: 0;
        left: 0;
        position: fixed;
        top: #{map-get($navbar-settings, Height)};
        transition: opacity .3s ease-out;
        z-index: -1;

        @include no-scrollbar();
    }

    &__NavigationItem {
        color: map-get($navbar-settings, Navigation-Color);
        display: flex;
        justify-content: center;
        max-width: 450px;
        padding-top: .5rem;
        padding-bottom: .5rem;
        width: 80%;

        &:not(:last-child) {
            border-bottom: 1px solid hsla(0, 0%, 100%, .2);
        }
    }
}