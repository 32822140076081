﻿.o-Weather {
    $select: &;
    cursor: pointer;

    .c-Card__Body {
        padding-bottom: 1.5rem;

        #{$select}__Right {
            .o-Icon {
                flex-shrink: 0;
            }

            span{
                white-space: nowrap;
            }
        }

        #{$select}__Left{
            span{
                white-space: nowrap;
            }
        }
    }
}
