﻿@import "../../Styles/Base/Helpers";

$tabs-settings: (
    Background: palette(Neutral, White)
);

$tabpane-settings : (
    Default: (
        Background: palette(Neutral, White),
        Color: palette(Dark, Lighter-60)
    ),
    Active: (
        Background: palette(Primary, Base),
        Color: palette(Neutral, White)
    ),
    Hover: (
        Color: palette(Primary, Base)
    )
);

.c-Tabs {
    --bd-Tabs-Background: #{map-get($tabs-settings, Background)};

    background: var(--bd-Tabs-Background);

    &__Nav {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__Content {
        padding-top: 2rem;
    }
}

.c-TabPane {

    $pane: &;

    --bd-TabPane-Background: #{map-deep-get($tabpane-settings, Default, Background)};
    --bd-TabPane-Color: #{map-deep-get($tabpane-settings, Default, Color)};

    align-items: center;
    background: var(--bd-TabPane-Background);
    border: 0;
    border-radius: 30px;
    color: var(--bd-TabPane-Color);
    display: flex;
    font-size: .75rem;
    font-weight: 700;
    margin: 0 .5rem;
    outline: none;
    padding: .5rem 1.25rem .5rem 1.25rem;
    transition: all .3s ease-out;

    &:hover:not(#{$pane}--Active) {
        --bd-TabPane-Color: #{map-deep-get($tabpane-settings, Hover, Color)};
    }

    &--Active {
        --bd-TabPane-Background: #{map-deep-get($tabpane-settings, Active, Background)};
        --bd-TabPane-Color: #{map-deep-get($tabpane-settings, Active, Color)};
    }

    &__Content {
        border: 0;
        outline: none;
    }    

    &__Counter {
        background: var(--bd-TabPane-Color);
        color: var(--bd-TabPane-Background);
        margin-left: .5rem;
        transition: all .3s ease-out;
    }
}