﻿@import "../../Styles/Base/Helpers";

$button-settings: (
    Size: (
        ExtraSmall: (
            YPadding: 2px,
            XPadding: 17px,
            Icon: 12px
        ),
        Small: (
            YPadding: 7px,
            XPadding: 22px,
            Icon: 18px
        ),
        Medium: (
            YPadding: 12px,
            XPadding: 27px,
            Icon: 26px
        ),
        Large: (
            YPadding: 17px,
            XPadding: 38px,
            Icon: 34px
        ),
        ExtraLarge: (
            YPadding: 20px,
            XPadding: 43px,
            Icon: 42px
        ),
    ),
    Theme: (
        Primary: (
            Main: palette(Primary),
            Minor: palette(Neutral, White),
            Hover: palette(Primary, Darker-10),
            Active: palette(Primary, Darker-20),
            HighlightBase: palette(Highlight, Base, 1, Hoteldoor)
        ),
        Secondary: (
            Main: palette(Secondary),
            Minor: palette(Neutral, White),
            Hover: palette(Secondary, Darker-10),
            Active: palette(Secondary, Darker-20),
            HighlightBase: palette(Highlight, Base, 1, Hoteldoor)
        ),
        Information: (
            Main: palette(Information),
            Minor: palette(Neutral, White),
            Hover: palette(Information, Darker-10),
            Active: palette(Information, Darker-20),
            HighlightBase: palette(Highlight, Base, 1, Hoteldoor)
        ),
        Success: (
            Main: palette(Success),
            Minor: palette(Neutral, White),
            Hover: palette(Success, Darker-10),
            Active: palette(Success, Darker-20),
            HighlightBase: palette(Highlight, Base, 1, Hoteldoor)
        ),
        Warning: (
            Main: palette(Warning),
            Minor: palette(Neutral, White),
            Hover: palette(Warning, Darker-10),
            Active: palette(Warning, Darker-20),
            HighlightBase: palette(Highlight, Base, 1, Hoteldoor)
        ),
        Danger: (
            Main: palette(Danger),
            Minor: palette(Neutral, White),
            Hover: palette(Danger, Darker-10),
            Active: palette(Danger, Darker-20),
            HighlightBase: palette(Highlight, Base, 1, Hoteldoor)
        ),
        Dark: (
            Main: palette(Dark, Lighter-20),
            Minor: palette(Neutral, White),
            Hover: palette(Dark, Lighter-10),
            Active: palette(Dark),
            HighlightBase: palette(Highlight, Base, 1, Hoteldoor)
        ),
        Light: (
            Main: palette(Dark, Lighter-80),
            Minor: palette(Dark, Lighter-30),
            Hover: palette(Dark, Lighter-70),
            Active: palette(Dark, Lighter-60),
            HighlightBase: palette(Highlight, Base, 1, Hoteldoor)
        )
    ),
    Skin: (
        Link: (
            Hover: palette(Dark, Lighter-80),
            Active: palette(Dark, Lighter-70)
        )
    ),
    FontSize: .875rem
);

.o-Button {
    align-items: center;
    border-color: transparent;
    border-style: solid;
    border-width: 2px;
    display: inline-flex;
    font-size: map-get($button-settings, FontSize);
    font-weight: 700;
    justify-content: center;
    line-height: 1.6;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease;
    user-select: none;
    vertical-align: middle;

    $button: &;

    &:focus {
        outline: none;
    }

    @each $size, $properties in map-get($button-settings, Size) {
        &--#{$size} {
            padding-bottom: map-get($properties, YPadding);
            padding-left: map-get($properties, XPadding);
            padding-right: map-get($properties, XPadding);
            padding-top: map-get($properties, YPadding);

            &#{$button}--IconOnly {
                @if $size == ExtraSmall {
                    padding-bottom: calc(#{map-get($properties, YPadding)} + 5px);
                    padding-left: calc(#{map-get($properties, YPadding)} + 5px);
                    padding-right: calc(#{map-get($properties, YPadding)} + 5px);
                    padding-top: calc(#{map-get($properties, YPadding)} + 5px);
                }
                @else if $size == Small {
                    padding-bottom: calc(#{map-get($properties, YPadding)} + 2px);
                    padding-left: calc(#{map-get($properties, YPadding)} + 2px);
                    padding-right: calc(#{map-get($properties, YPadding)} + 2px);
                    padding-top: calc(#{map-get($properties, YPadding)} + 2px);
                }
                @else if $size == Medium {
                    padding-bottom: calc(#{map-get($properties, YPadding)} - 2px);
                    padding-left: calc(#{map-get($properties, YPadding)} - 2px);
                    padding-right: calc(#{map-get($properties, YPadding)} - 2px);
                    padding-top: calc(#{map-get($properties, YPadding)} - 2px);
                }
                @else if $size == Large {
                    padding-bottom: calc(#{map-get($properties, YPadding)} - 6px);
                    padding-left: calc(#{map-get($properties, YPadding)} - 6px);
                    padding-right: calc(#{map-get($properties, YPadding)} - 6px);
                    padding-top: calc(#{map-get($properties, YPadding)} - 6px);
                }
                @else if $size == ExtraLarge {
                    padding-bottom: calc(#{map-get($properties, YPadding)} - 8px);
                    padding-left: calc(#{map-get($properties, YPadding)} - 8px);
                    padding-right: calc(#{map-get($properties, YPadding)} - 8px);
                    padding-top: calc(#{map-get($properties, YPadding)} - 8px);
                }

                > #{$button}__Icon {
                    height: map-get($properties, Icon);
                    width: map-get($properties, Icon);
                }
            }
        }
    }

    $link-skin: map-deep-get($button-settings, Skin, Link);

    @each $theme, $colors in map-get($button-settings, Theme) {
        &--#{$theme} {
            --bd-Button-Background: #{map-get($colors, Main)};
            --bd-Button-Color: #{map-get($colors, Minor)};

            &#{$button}--Solid {
                background: var(--bd-Button-Background);
                border-color: var(--bd-Button-Background);
                color: var(--bd-Button-Color);

                &:hover:not(#{$button}--Disabled):not(#{$button}--Processing):not(#{$button}--Processed) {
                    --bd-Button-Background: #{map-get($colors, Hover)};
                }

                &:active:not(#{$button}--Disabled):not(#{$button}--Processing):not(#{$button}--Processed) {
                    --bd-Button-Background: #{map-get($colors, Active)};
                }
            }

            &#{$button}--Outline {
                background: var(--bd-Button-Color);
                border-color: var(--bd-Button-Background);
                color: var(--bd-Button-Background);

                &:hover:not(#{$button}--Disabled):not(#{$button}--Processing):not(#{$button}--Processed) {
                    --bd-Button-Background: #{map-get($colors, Hover)};
                }

                &:active:not(#{$button}--Disabled):not(#{$button}--Processing):not(#{$button}--Processed) {
                    --bd-Button-Background: #{map-get($colors, Active)};
                }
            }

            &#{$button}--Link {
                --bd-Button-Background: transparent;

                @if $theme == Light {
                    --bd-Button-Color: #{map-get($colors, Active)};
                }
                @else {
                    --bd-Button-Color: #{map-get($colors, Main)};
                }

                background: var(--bd-Button-Background);
                border-color: var(--bd-Button-Background);
                color: var(--bd-Button-Color);

                &:hover:not(#{$button}--Disabled):not(#{$button}--Processing):not(#{$button}--Processed) {
                    --bd-Button-Background: #{map-get($link-skin, Hover)};
                }

                &:active:not(#{$button}--Disabled):not(#{$button}--Processing):not(#{$button}--Processed) {
                    --bd-Button-Background: #{map-get($link-skin, Active)};
                }
            }

            &#{$button}--Ghost {
                --bd-Button-Background: transparent;
                --bd-Button-Color: #{map-get($colors, Main)};
                background: var(--bd-Button-Background);
                color: var(--bd-Button-Color);
            }

            &#{$button}--SpeechBubble {
                --bd-Button-Background: #{map-get($colors, Minor)};
                --bd-Button-Color: #{map-get($colors, HighlightBase)};
                background-color: var(--bd-Button-Background);
                color: var(--bd-Button-Color);
                border-radius: 5px;
                box-shadow: 0 0 25px 0 rgba(0,0,0,0.16);
                font-weight: bold;
                overflow: visible; //Needed to show the :after

                &:after {
                    content: "";
                    position: absolute;
                    top: 100%;
                    left: calc(50% - 0.5rem);
                    width: 0;
                    height: 0;
                    border-width: 0.5rem 0.5rem 0 0.5rem;
                    border-color: white transparent transparent transparent;
                    border-style: solid;
                }
            }
        }
    }

    &--Ghost {
        border-radius: 0 !important;
        border-width: 0 !important;
        padding: 0 !important;
        text-decoration: none;
        user-select: auto;

        &:hover:not(#{$button}--Disabled):not(#{$button}--Processing):not(#{$button}--Processed) {
            opacity: .8;
            text-decoration: underline;
        }

        &:active:not(#{$button}--Disabled):not(#{$button}--Processing):not(#{$button}--Processed) {
            opacity: .4;
            text-decoration: underline;
        }
    }

    &--Circle {
        border-radius: 1.72rem;
    }

    &--Rounded {
        border-radius: 0.42rem;
    }

    &--Block {
        width: 100% !important;
    }

    &--IconOnly {
        &#{$button}--Circle {
            border-radius: 50% !important;
        }

        #{$button}__Icon {
            margin-right: 0 !important;
        }
    }

    &--InheritFontSize {
        font-size: 1em;
    }

    &--Disabled {
        cursor: not-allowed;
        opacity: .4;
    }

    &--Processing {
        pointer-events: none;

        #{$button}__Loader {
            &--Start {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &--Processed {
        pointer-events: none;

        #{$button}__Loader {
            &--Stop {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &__Icon {
        fill: currentColor;
        margin-right: .5em;
    }

    &__Loader {
        align-items: center;
        background: inherit;
        display: inline-flex;
        justify-content: center;
        opacity: 0;
        visibility: hidden;
        @include position(absolute, 0, 0, 0, 0);

        #{$button}__Icon {
            margin-right: 0;
        }
    }

    &__Label {
        color: currentColor;
        font-size: 1em;
    }
}
