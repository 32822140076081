@import "../../Styles/Base/Helpers";

$logo-settings: (
    Size: (
        ExtraSmall: (
            Height: 40px,
            MaxWidth: 150px
        ),
        Small: (
            Height: 50px,
            MaxWidth: 200px
        ),
        Medium: (
            Height: 65px,
            MaxWidth: 250px
        ),
        Large: (
            Height: 80px,
            MaxWidth: 300px
        ),
        ExtraLarge: (
            Height: 90px,
            MaxWidth: 350px
        )
    )
);

.o-Logo {
    align-items: center;
    display: flex;

    $logo: &;

    @each $breakpoint in map-keys($grid-breakpoints) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        $separaror: if($infix=="", "--", "-");

        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            @each $size, $properties in map-get($logo-settings, Size) {
                &#{$infix}#{$separaror}#{$size} {
                    --bd-Logo-Height: #{map-get($properties, Height)};
                    --bd-Logo-MaxWidth: #{map-get($properties, MaxWidth)};
                }
            }
        }
    }

    &__NativeImage {
        height: var(--bd-Logo-Height);
        width: auto;

        @include media-breakpoint-up(Tablet) {
            max-width: var(--bd-Logo-MaxWidth);
        }
    }
}