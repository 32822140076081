﻿@import "../../Styles/Base/Helpers";

$avatar-settings: (
    Size: (
        Large: (
            BorderWidth: 4px,
            Width: 80px,
        ),
        Medium: (
            BorderWidth: 3px,
            Width: 52px
        ),
        Small: (
            BorderWidth: 2px,
            Width: 25px
        )
    ),
    Theme: (
        Primary: palette(Primary),
        Secondary: palette(Secondary),
        Light: palette(Neutral, White)
    )
);

.o-Avatar {
    background-color: var(--bd-Avatar-BorderColor);
    border-color: var(--bd-Avatar-BorderColor);
    border-width: var(--bd-Avatar-BorderWidth);
    border-radius: 50%;
    border-style: solid;
    display: block;
    position: relative;
    width: var(--bd-Avatar-Width);

    @each $size, $properties in map-get($avatar-settings, Size) {
        &--#{$size} {
            --bd-Avatar-BorderWidth: #{map-get($properties, BorderWidth)};
            --bd-Avatar-Width: #{map-get($properties, Width)};
        }
    }

    @each $theme, $color in map-get($avatar-settings, Theme) {
        &--#{$theme} {
            --bd-Avatar-BorderColor: #{$color};
        }
    }

    &--Comics {
        &:after {
	        border: calc(var(--bd-Avatar-BorderWidth) * 2) solid transparent;
	        border-bottom: 0;
	        border-right: 0;
	        border-top-color: var(--bd-Avatar-BorderColor);
	        bottom: calc(var(--bd-Avatar-BorderWidth) * -4);
            content: '';
	        height: 0;
	        left: 50%;
	        margin-bottom: calc(var(--bd-Avatar-BorderWidth) + 1px);
	        margin-left: calc(var(--bd-Avatar-BorderWidth) * -1);
	        position: absolute;
	        width: 0;
        }
    }

    &__Image {
        border-radius: 50%;
    }
}