﻿@import "../../Styles/Base/Helpers";

$spacing-settings: (
    Margin: (
        Properties: margin,
        Values: map-merge($spacers, (Auto: auto))
    ),
    Margin-X: (
        Properties: margin-right margin-left,
        Values: map-merge($spacers, (Auto: auto))
    ),
    Margin-Y: (
        Properties: margin-top margin-bottom,
        Values: map-merge($spacers, (Auto: auto))
    ),
    Margin-Top: (
        Properties: margin-top,
        Values: map-merge($spacers, (Auto: auto))
    ),
    Margin-Right: (
        Properties: margin-right,
        Values: map-merge($spacers, (Auto: auto))
    ),
    Margin-Bottom: (
        Properties: margin-bottom,
        Values: map-merge($spacers, (Auto: auto))
    ),
    Margin-Left: (
        Properties: margin-left,
        Values: map-merge($spacers, (Auto: auto))
    ),
    Padding: (
        Properties: padding,
        Values: $spacers
    ),
    Padding-X: (
        Properties: padding-right padding-left,
        Values: $spacers
    ),
    Padding-Y: (
        Properties: padding-top padding-bottom,
        Values: $spacers
    ),
    Padding-Top: (
        Properties: padding-top,
        Values: $spacers
    ),
    Padding-Right: (
        Properties: padding-right,
        Values: $spacers
    ),
    Padding-Bottom: (
        Properties: padding-bottom,
        Values: $spacers
    ),
    Padding-Left: (
        Properties: padding-left,
        Values: $spacers
    ),
    Overflow: (
        Properties: overflow,
        Values: (
            Hidden: hidden
        )
    ),
    Display: (
        Properties: display,
        Values: (
            Flex: flex,
            InlineFlex: inline-flex,
            None: none
        )
    ),
    Flex: (
        Properties: flex,
        Values: (
            Fill: 1 1 auto
        )
    ),
    FlexDirection: (
        Properties: flex-direction,
        Values: (
            Row: row,
            Column: column,
            RowReverse: row-reverse,
            ColumnReverse: column-reverse
        )
    ),
    FlexGrow: (
        Properties: flex-grow,
        Values: (
            0: 0,
            1: 1,
        )
    ),
    FlexShrink: (
        Properties: flex-shrink,
        Values: (
            0: 0,
            1: 1,
        )
    ),
    FlexWrap : (
        Properties: flex-wrap,
        Values: (
            Reverse: wrap-reverse,
            0: nowrap,
            1: wrap
        )
    ),
    JustifyContent: (
        Properties: justify-content,
        Values: (
            Start: flex-start,
            End: flex-end,
            Center: center,
            Between: space-between,
            Around: space-around,
            Evenly: space-evenly,
        )
    ),
    AlignItems: (
        Properties: align-items,
        Values: (
            Start: flex-start,
            End: flex-end,
            Center: center,
            Baseline: baseline,
            Stretch: stretch,
        ) 
    ),
    AlignContent: (
        Properties: align-content,
        Values: (
            start: flex-start,
            end: flex-end,
            center: center,
            between: space-between,
            around: space-around,
            stretch: stretch,
        )
    ),
    AlignSelf: (
      Properties: align-self,
      Values: (
          Auto: auto,
          Start: flex-start,
          End: flex-end,
          Center: center,
          Baseline: baseline,
          Stretch: stretch,
        )
    ),
    Order: (
        Properties: order,
        Values: (
            First: -1,
            0: 0,
            1: 1,
            2: 2,
            3: 3,
            4: 4,
            5: 5,
            Last: 6,
        ),
    ),
    TextAlign: (
        Properties: text-align,
        Values: (
            Left: left,
            Right: right,
            Center: center
        )
    ),
    Height: (
        Properties: height,
        Values: (
            100: 100%,
            Auto: auto
        )
    ),
    Width: (
        Properties: width,
        Values: (
            100: 100%,
            Auto: auto
        )
    )
);

@each $breakpoint in map-keys($grid-breakpoints) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    $separaror: if($infix=="", "--", "-");

    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        @each $utility, $settings in $spacing-settings {
            $values: map-get($settings, Values);

            @if type-of($values) == "string" or type-of(nth($values, 1)) != "list" {
                $values: zip($values, $values);
            }

            @each $key, $value in $values{
                $properties: map-get($settings, Properties);

                @if type-of($properties) == "string" {
                    $properties: append((), $properties);
                }

                .u-#{$utility}#{$infix}#{$separaror}#{$key} {
                    @each $property in $properties {
                        #{$property}: $value !important;
                    }
                }
            }
        } 
    }
}