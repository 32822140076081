/*#region Breakpoints */

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);

    @if $min {
        @media (min-width: $min) {
            @content;
        }
    }
    @else {
        @content;
    }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
    $max: breakpoint-max($name, $breakpoints);

    @if $max {
        @media (max-width: $max) {
            @content;
        }
    }
    @else {
        @content;
    }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($lower, $breakpoints);
    $max: breakpoint-max($upper, $breakpoints);

    @if $min !=null and $max !=null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    }

    @else if $max==null {
        @include media-breakpoint-up($lower, $breakpoints) {
            @content;
        }
    }

    @else if $min==null {
        @include media-breakpoint-down($upper, $breakpoints) {
            @content;
        }
    }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    $next: breakpoint-next($name, $breakpoints);
    $max: breakpoint-max($next);

    @if $min !=null and $max !=null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    }

    @else if $max==null {
        @include media-breakpoint-up($name, $breakpoints) {
            @content;
        }
    }

    @else if $min==null {
        @include media-breakpoint-down($next, $breakpoints) {
            @content;
        }
    }
}

/*#endregion*/