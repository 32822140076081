﻿@import "../../Styles/Base/Helpers";

$table-settings: ( Theme: ( Primary: ( Background: palette(Secondary, Base), Color: palette(Neutral, White) ), Light: ( Background: palette(Neutral, Background), Color: palette(Dark, Lighter-20) ), ), TableHead: ( FontSize: 1rem ), TableRow: ( FontSize: .75rem ) );

.c-Table {
    overflow-x: auto;

    &--Solid {
        @each $theme, $colors in map-get($table-settings, Theme) {
            &.c-Table--#{$theme} {
                --bd-Table-Scrollbar-Background: #{map-get($colors, Background)};
                --bd-Table-Scrollbar-Color: #{map-get($colors, Color)};

                @include scrollbar(5px, var(--bd-Table-Scrollbar-Color), $background-color: var(--bd-Table-Scrollbar-Background), $border-radius: 5px);

                table {
                    margin-bottom: .5rem;
                }

                tr {
                    &:last-child {
                        td {
                            background-color: #{map-get($colors, Background)};
                            color: #{map-get($colors, Color)};
                        }
                    }
                }
            }
        }
    }

    table {
        width: 100%;
    }

    th {
        color: palette(Dark, Lighter-20);
        font-size: map-deep-get($table-settings, TableHead, FontSize);
        padding: 1rem .7rem;
        text-align: center;
    }

    tr,
    th,
    td {
        border: 0;
    }

    tr {
        td {
            &.no-border{
                border-top: 0;
            }

            border-top: 1px solid palette(Dark, Lighter-70);
            color: palette(Dark, Lighter-20);
        }
    }

    tr:last-child {
        border-bottom: 1px solid palette(Dark, Lighter-70);
    }


    td {
        font-size: map-deep-get($table-settings, TableRow, FontSize);
        padding: .7rem .7rem;
        text-align: center;
        white-space: nowrap;
    }
}
