/*#region Scrollbar */

/// Mixin to customize scrollbars
/// Beware, this does not work in all browsers
/// @author Hugo Giraudel
/// @param {Length} $size - Horizontal scrollbar's height and vertical scrollbar's width
/// @param {Color} $foreground-color - Scrollbar color
/// @param {Color} $background-color [mix($foreground-color, white, 50%)] - Scrollbar color
/// @param {Length} $border-radius - Scrollbar radius
/// @example scss - Scrollbar styling
/// @include scrollbars(.5em, slategray);
@mixin scrollbar($size, $foreground-color, $background-color: mix($foreground-color, white, 50%), $border-radius: 0) {
    // For Google Chrome
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
        border-radius: $border-radius;
    }

    &::-webkit-scrollbar-track {
        background: $background-color;
    }
    // For Internet Explorer
    & {
        scrollbar-face-color: $foreground-color;
        scrollbar-track-color: $background-color;
    }
}

@mixin no-scrollbar() {
    &::-webkit-scrollbar {
        height: 0;
        width: 0;
    }

    &::-webkit-scrollbar-thumb {
        height: 0;
        width: 0;
    }

    &::-webkit-scrollbar-track {
        height: 0;
        width: 0;
    }
}

/*#endregion*/