@import "../../Styles/Base/Helpers";

$card-settings: (
    Background: palette(Neutral, White),
    BorderRadius: var(--bd-Card-Radius),
    BorderColor: palette(Dark, Lighter-80)
);

.c-Card {
    display: flex;
    flex-direction: column;
    background-color: map-get($card-settings, Background);
    background-clip: border-box;
    border-radius: map-get($card-settings, BorderRadius);
    min-width: 0;
    position: relative;
    word-wrap: break-word;
    --bd-Card-BorderColor: #{map-get($card-settings, BorderColor)};

    $card: &;

    &--Vertical {
        #{$card}__Body {
            flex-direction: column;
        }

        &#{$card}--Custom {
            > #{$card}__Body {
                padding-top: 3rem;
            }
        }
    }

    &--Horizontal {
        #{$card}__Body {
            flex-direction: row;
            align-items: center;
        }

        &#{$card}--Custom {
            > #{$card}__Body {
                padding-top: 2rem;
                padding-bottom: 2rem;

                @include media-breakpoint-up(Surface) {
                    padding-left: 2.5rem;
                    padding-right: 2.5rem;
                }
            }
        }
    }

    &--Bordered {
        border: 1px solid var(--bd-Card-BorderColor);
    }

    &--FitParent {
        height: 100%;
    }

    &--Stretch {
        > #{$card}__Header,
        > #{$card}__Body,
        > #{$card}__Footer {
            padding: 0;
        }
    }

    &__Header {
        align-items: center;
        display: flex;
        flex-shrink: 0;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 3rem 1rem .5rem;
        position: relative;

        #{$card}__Title {
            align-items: center;
            display: flex;
            margin: .5rem;
            margin-left: 0;
        }

        #{$card}__Toolbar {
            align-items: center;
            display: flex;
            margin: .5rem 0;
            flex-wrap: wrap;
        }

        #{$card}__Divider {
            @include position(absolute, $left: 1rem, $bottom: 0);
        }

        @include media-breakpoint-up(Surface) {
            padding: 3rem 4.5rem .5rem;

            #{$card}__Divider {
                left: 4.5rem;
            }
        }
    }

    &__Body {
        padding-bottom: 3rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1.5rem;
        position: relative;

        @include media-breakpoint-up(Surface) {
            padding-left: 4.5rem;
            padding-right: 4.5rem;
        }
    }

    &__Divider {
        padding: 0;
        min-width: 0;
        width: calc(100% - 2rem);

        @include media-breakpoint-up(Surface) {
            width: calc(100% - 9rem);
        }
    }
}