@import "../../Styles/Base/Helpers";

$divider-settings: (
    Background: palette(Dark, Lighter-80),
    Variant: (
        Horizontal: (
            Small: .5rem,
            Medium: 1.5rem
        ),
        Vertical: (
            Small: .25rem,
            Medium: .5rem
        )
    )
);

.o-Divider {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    position: relative;
    user-select: none;
    
    $divider: &;

    $divider-horizontal-size: map-deep-get($divider-settings, Variant, Horizontal);
    &--Horizontal {      
        clear: both;
        padding: var(--bd-Divider-Size) 0;
        min-width: 100%;
        width: 100%;

        &#{$divider}--Small {
            --bd-Divider-Size: #{map-get($divider-horizontal-size, Small)};
        }

        &#{$divider}--Medium {
            --bd-Divider-Size: #{map-get($divider-horizontal-size, Medium)};
        }

        &:before {
            content: '';
            height: 1px;
            background-color: map-get($divider-settings, Background);
            @include position(absolute, $left: 0, $right: 0);
        }

        #{$divider}__Badge {
            margin-left: var(--bd-Divider-Size);
            margin-right: var(--bd-Divider-Size);
            position: relative;
        }
    }
    
    $divider-vertical-size: map-deep-get($divider-settings, Variant, Vertical);
    &--Vertical {
        clear: both;
        height: 100%;
        max-height: var(--bd-Divider-Size);
        padding: 0;
        width: 1px;

        &#{$divider}--Small {
            --bd-Divider-Size: calc(100% - #{map-get($divider-vertical-size, Small) * 2});
        }

        &#{$divider}--Medium {
            --bd-Divider-Size: calc(100% - #{map-get($divider-vertical-size, Medium) * 2});
        }

        &:before {
            content: '';
            background-color: map-get($divider-settings, Background);
            width: 1px;
            @include position(absolute, $top: 0, $bottom: 0);
        }

        #{$divider}__Badge {
            display: none;
        }
    }

    &--Left {
        justify-content: flex-start;
    }

    &--Center {
        justify-content: center;
    }

    &--Right {
        justify-content: flex-end;
    }

    &__Badge {
        padding-left: 1.5em !important;
        padding-right: 1.5em !important;
    }
}
