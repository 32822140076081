@import "../../Styles/Base/Helpers";

$multimedia-settings: (
    OverlayColor: palette(Neutral, Black)
);

.o-Multimedia {
    $this: &;

    display: block;
    overflow: hidden;
    position: relative;

    &--1by1 {
        @include aspect-ratio(1, 1);
    }

    &--1by2 {
        @include aspect-ratio(1, 2);
    }

    &--2by1 {
        @include aspect-ratio(2, 1);
    }

    &--2by3 {
        @include aspect-ratio(2, 3);
    }

    &--3by2 {
        @include aspect-ratio(3, 2);
    }

    &--3by4 {
        @include aspect-ratio(3, 4);
    }

    &--4by3 {
        @include aspect-ratio(4, 3);
    }

    &--4by5 {
        @include aspect-ratio(4, 5);
    }

    &--5by3 {
        @include aspect-ratio(5, 3);
    }

    &--5by4 {
        @include aspect-ratio(5, 4);
    }

    &--16by9 {
        @include aspect-ratio(16, 9);
    }

    &--16by10 {
        @include aspect-ratio(16, 10);
    }

    &--FitParent {
        height: 100%;
    }

    &--Custom {
        &:before {
            content: '';
            display: block;
            position: relative;
            width: 100%;
        }
    }

    &--PanZoom {
        &:hover {
            #{$this}__Source {
                transform: scale(1.05);
            }
        }

        #{$this}__Source {
            transition: transform .4s ease-out;
        }
    }

    &--Muted {
        opacity: .5;
    }

    &__Source {
        border: 0;
        height: 100%;
        object-fit: cover;
        width: 100%;
        @include position($top: 0, $left: 0);

        @at-root iframe#{&} {
            height: 56.25vw;
            min-height: 100%;
            min-width: 100%;
            object-fit: unset;
            width: 177.77777778vh !important;
            @include centerer();
        }

        &img[src=''],
        &img:not([src]) {
            opacity: 0;
        }
    }

    &__Caption {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include position(absolute, 0, 0, 0, 0);
        @include z-index(Inside);

        &:before {
            background: map-get($multimedia-settings, OverlayColor);
            content: '';
            opacity: .4;
            @include position(absolute, 0, 0, 0, 0);
            @include z-index(Below);
        }
    }
}