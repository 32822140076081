﻿@use "sass:math";
@import "../../Styles/Base/Helpers";

$dot-width: 10px !default;
$dot-height: 10px !default;
$dot-radius: math.div($dot-width, 2) !default;

$dot-color: #9880ff !default;
$dot-bg-color: $dot-color !default;
$dot-before-color: $dot-color !default;
$dot-after-color: $dot-color !default;

$dot-spacing: $dot-width+math.div($dot-width, 2) !default;

$ratio1: .8;
$ratio2: .7;

$loader-settings: (
    Theme: (
        Primary: palette(Primary),
        Secondary: palette(Secondary),
        Information: palette(Information),
        Success: palette(Success),
        Warning: palette(Warning),
        Danger: palette(Danger),
        Dark: palette(Dark, Lighter-20),
        Light: palette(Dark, Lighter-80),
    ),
    Variant: (),
    Position: ());

@mixin dot($width: $dot-width,
    $height: $dot-height,
    $radius: $dot-radius,
    $bg-color: $dot-bg-color,
    $color: $dot-color) {
    width: $width;
    height: $height;
    border-radius: $radius;
    background-color: $bg-color;
    color: $color;
}

@keyframes dot-stretching {
    0% {
        transform: scale(1.25, 1.25);
    }

    50%,
    60% {
        transform: scale($ratio1, $ratio1);
    }

    100% {
        transform: scale(1.25, 1.25);
    }
}

@keyframes dot-stretching-before {
    0% {
        transform: translate(0) scale($ratio2, $ratio2);
    }

    50%,
    60% {
        transform: translate(-20px) scale(1, 1);
    }

    100% {
        transform: translate(0) scale($ratio2, $ratio2);
    }
}

@keyframes dot-stretching-after {
    0% {
        transform: translate(0) scale($ratio2, $ratio2);
    }
    
    50%,
    60% {
        transform: translate(20px) scale(1, 1);
    }

    100% {
        transform: translate(0) scale($ratio2, $ratio2);
    }
}

.o-Loader {
    animation: dot-stretching 2s infinite ease-in;
    transform: scale(1.25, 1.25) translate(-50%, -50%);
    @include dot();
    @include z-index(Feedback);

    &--Absolute {
        left: 50%;
        position: absolute;
        top: 50%;
    }

    &--Relative {
        position: relative;
        left: 50%;
    }

    &::before,
    &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
    }

    &::before {
        @include dot($bg-color: $dot-before-color);
        animation: dot-stretching-before 2s infinite ease-in;
    }

    &::after {
        @include dot($bg-color: $dot-after-color);
        animation: dot-stretching-after 2s infinite ease-in;
    }

    @each $theme, $color in map-get($loader-settings, Theme) {
        &--#{$theme} {
            --bd-Loader-Color: #{$color};

            $dot-color: var(--bd-Loader-Color);
            $dot-bg-color: var(--bd-Loader-Color);
            $dot-before-color: var(--bd-Loader-Color);
            $dot-after-color: var(--bd-Loader-Color);

            @include dot($bg-color: $dot-bg-color, $color: $dot-color);

            &::before {
                @include dot($bg-color: $dot-before-color);
            }

            &::after {
                @include dot($bg-color: $dot-after-color);
            }
        }
    }
}

