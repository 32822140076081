@import "../../Styles/Base/Helpers";

$snackbar-settings: (
    Theme: (
        Information: (
            Main: palette(Information),
            Minor: palette(Neutral, White),
            Hover: palette(Information, Darker-10)
        ),
        Success: (
            Main: palette(Success),
            Minor: palette(Neutral, White),
            Hover: palette(Success, Darker-10)
        ),
        Warning: (
            Main: palette(Warning),
            Minor: palette(Neutral, White),
            Hover: palette(Warning, Darker-10)
        ),
        Danger: (
            Main: palette(Danger),
            Minor: palette(Neutral, White),
            Hover: palette(Danger, Darker-10),
        ),
        Standard: (
            Main: palette(Primary),
            Minor: palette(Neutral, White),
            Hover: palette(Primary, Darker-10)
        )
    )
);

@keyframes SnackbarMovein {
    0% {
        transform: translate(0, 50px);
    }
    100% {
        transform: translate(0, 0);
    }
}

.l-SnackbarSurface {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    width: 100%;
    @include position(fixed, $left: 0, $bottom: 1rem, $right: 0);
    @include z-index(Feedback);

    > * {
        margin-top: .5rem;
    }
}

.c-Snackbar {
    animation-name: SnackbarMovein;
    animation-duration: .15s;
    align-items: center;
    border-radius: .42rem;
    box-shadow: 0px 3px 5px -1px palette(Dark, $opacity: .2), 0px 6px 10px 0px palette(Dark, $opacity: .14), 0px 1px 18px 0px palette(Dark, $opacity: .12);
    display: flex;
    justify-content: space-between;
    min-width: 300px;
    max-width: 90vw;
    overflow: hidden;
    padding: .6rem 1rem;
    pointer-events: auto;
    position: relative;
    transition: background-color .15s ease;
    user-select: none;

    $snackbar: &;

    @include media-breakpoint-up(Tablet) {
        min-width: 500px;
        max-width: 720px;
    }

    @include media-breakpoint-up(Laptop) {
        max-width: 1140px;
    }

    @each $theme, $colors in map-get($snackbar-settings, Theme) {
        &--#{$theme} {
            background: map-get($colors, Main);
            color: map-get($colors, Minor);

            &:hover {
                background: map-get($colors, Hover);
            }
        }
    }

    &--MaxWidth100 {
        max-width: 100%;
    }

    &--Thickness {
        &Thin {
            padding: .4rem 1rem;
        }

        &Normal {
            padding: .6rem 1rem;
        }

        &Thick {
            padding: .8rem 1rem;
        }
    }

    &__Content {
        align-items: center;
        display: flex;
        flex: 1;
    }

    &__Icon {
        margin-right: 1rem;
    }

    &__Message {
        color: currentColor;
        flex: 1;
    }

    &__Close {
        cursor: pointer;
        margin-left: 1rem;
        opacity: .8;
        transition: opacity .15s ease;

        &:hover {
            opacity: 1;
        }
    }

    &__AnchorButton {
        text-decoration: underline;
    }
}