@import "../../Styles/Base/Helpers";

$navigation-settings: ( 
    Default: ( 
        Background: palette(Neutral, White), 
        Color: palette(Dark, Lighter-20) 
    ), 
    Active: ( 
        Background: palette(Primary, Base), 
        Color: palette(Neutral, White) 
    ), 
    Hover: ( 
        Background: palette(Dark, Lighter-80) 
    ), 
    Nav: (
        BorderRadius: var(--bd-Card-Radius) 
    ), 
    PaneHeader: ( 
        Background: palette(Primary, Base) 
    )    
);

.c-Navigation {

    $navigation: &;

    --bd-Navigation-BorderRadius: #{map-deep-get($navigation-settings, Nav, BorderRadius)};

    &--Single {
        @include media-breakpoint-up (Surface) {
            #{$navigation}__PaneHeader--Active {
                left: 1rem;
            }
        }
    }

    &--Sticky {
        @include media-breakpoint-down(Surface) {
            #{$navigation}__Nav {
                bottom: 1rem;
                transform: translate(-50%, 0);
            }
        }
    }

    &__Nav {
        align-items: center;
        background: map-deep-get($navigation-settings, Default, Background);
        box-shadow: 0 0 26px 0 rgba(0,0,0,0.16);
        border-radius: var(--bd-Navigation-BorderRadius);
        bottom: -1rem;
        display: inline-flex;
        flex-direction: row;
        left: 50%;
        height: 80px;
        overflow: visible;
        padding: .5rem;
        position: fixed;
        transform: translate(-50%, 100%);
        transition: transform .5s ease-out;
        @include z-index(Sticky);

        @include media-breakpoint-up(Surface) {
            bottom: auto;
            flex-direction: column;
            left: 1rem;
            height: auto;
            top: calc((100vh + #{$navbar-height}) / 2);
            transform: translate(0, -50%);
            width: 80px;
        }
    }

    &__Item {
        &:not(:last-child) {
            margin-right: .5rem;

            @include media-breakpoint-up(Surface) {
                margin-bottom: .5rem;
                margin-right: 0;
            }
        }
    }

    &__Toggle {
        --bd-Nav-Toggle-Background: #{map-deep-get($navigation-settings, Default, Background)};
        --bd-Nav-Toggle-Color: #{map-deep-get($navigation-settings, Default, Color)};
        align-items: center;
        background: var(--bd-Nav-Toggle-Background);
        border: 0;
        border-radius: var(--bd-Navigation-BorderRadius);
        color: var(--bd-Nav-Toggle-Color);
        display: flex;
        flex-direction: column;
        outline: none;
        padding: .75rem .75rem;
        transition: all .3s ease-out;
        width: 100%;

        &--Active {
            --bd-Nav-Toggle-Background: #{map-deep-get($navigation-settings, Active, Background)};
            --bd-Nav-Toggle-Color: #{map-deep-get($navigation-settings, Active, Color)};
            transform: translate(0, -.5rem);

            @include media-breakpoint-up(Surface) {
                transform: translate(.5rem, 0);

                .o-Icon {
                    margin-left: .5rem;
                }
            }
        }

        &:not(#{$navigation}__Toggle--Active) {
            &:hover {
                --bd-Nav-Toggle-Background: #{map-deep-get($navigation-settings, Hover, Background)};
            }
        }

        @include media-breakpoint-down(Surface) {
            .o-Icon {
                transform: rotate(-90deg);
            }
        }

        @include media-breakpoint-up(Surface) {
            padding: 1rem 0.5rem;

            &--Active {
                padding: 1rem;
            }

            .o-Icon {
                display: none;
            }
        }
    }

    &__PaneHeader {
        --bd-Nav-PaneHeader-Background: #{map-deep-get($navigation-settings, PaneHeader, Background)};
        background: var(--bd-Nav-PaneHeader-Background);
        color: var(--bd-Neutral-White);
        display: flex;
        flex-direction: column;
        left: 0;
        padding: 4rem 1rem;
        position: relative;
        width: 100%;
        z-index: 1;

        @include media-breakpoint-up (Surface) {
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            left: 5rem;
            padding: 4rem;
            position: absolute;
            top: 50%;
            transform: translate(calc(-100% - 1.5rem), -50%);
            width: 400px;

            &--Enabled {
                position: fixed;
                top: calc((100vh + var(--bd-Navbar-Height)) / 2);
                transition: left .3s ease-out, transform .3s ease-out;
            }

            &--Active {
                left: 5rem;
                position: fixed;
                transform: translate(0, -50%);
            }
        }
    }

    &__Label {
        font-size: 10px;
        white-space: nowrap;
    }
}
