@import "../../Styles/Base/Helpers";

$datepicker-settings: ( Validation: ( Invalid: palette(Danger), Valid: palette(Success) ), State: ( Disabled: ( Cursor: not-allowed, Color: palette(Dark, Lighter-50), Background: palette(Dark, Lighter-80), ), ), Field: ( Outline: palette(Dark), ), Panel: ( Background: palette(Neutral, White), BorderColor: palette(Dark, Lighter-75), BorderRadius: var(--bd-Widget-Radius), Width: 280px, ), Cell: ( DisabledColor: palette(Dark, Lighter-70), OutOfViewColor: palette(Dark, Lighter-55), SelectedColor: palette(Neutral, White), RangeStart: palette(Neutral, White), InRangeColor: palette(Neutral, White), RangeEnd-EndColor: palette(Neutral, White), DefaultColor: palette(Dark), DefaultBackground: palette(Neutral, White), HoverBackground: palette(Dark, Lighter-75), SelectedBackground: palette(Primary, Base, 1, Hoteldoor), RangeStartBackground:palette(Primary, Base, 1, Hoteldoor), InRangeBackground:palette(Primary, Lighter-20, 1, Hoteldoor), RangeEnd-EndBackground:palette(Primary, Base, 1, Hoteldoor)) );

.o-Datepicker {
    outline: 0;
    position: relative;
    --bd-TextField-Outline: #{map-deep-get($datepicker-settings, Field, Outline)};
    --bd-Panel-Width: #{map-deep-get($datepicker-settings, Panel, Width)};
    --bd-Panel-Background: #{map-deep-get($datepicker-settings, Panel, Background)};
    --bd-Panel-BorderColor: #{map-deep-get($datepicker-settings, Panel, BorderColor)};
    --bd-Cell-Color: #{map-deep-get($datepicker-settings, Cell, OutOfViewColor)};
    --bd-Cell-Background: #{map-deep-get($datepicker-settings, Cell, DefaultBackground)};

    $datepicker: &;

    @each $validation, $color in map-get($datepicker-settings, Validation) {
        &--#{$validation} {
            @if $validation == Valid {
                &#{$datepicker}--Modified {
                    --bd-TextField-Outline: #{$color};
                    --bd-TextField-ValidationColor: #{$color};

                    #{$datepicker}__NativeControl {
                        box-shadow: 0 0 0 1px var(--bd-TextField-ValidationColor) !important;
                    }
                }
            }
            @else {
                --bd-TextField-Outline: #{$color};
                --bd-TextField-ValidationColor: #{$color};

                #{$datepicker}__NativeControl {
                    box-shadow: 0 0 0 1px var(--bd-TextField-ValidationColor) !important;
                }
            }
        }
    }

    &--Focused {
        #{$datepicker}__NativeControl {
            box-shadow: 0 0 0 1px var(--bd-TextField-Outline);
        }
    }

    &__Separator {
        display: inline-flex;
    }

    &__ActiveBar {
    }

    &__Widget {
        box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
        border-radius: map-deep-get($datepicker-settings, Panel, BorderRadius);
        display: flex;
        flex-direction: column;
        left: 0;
        margin-top: 5px;
        overflow: hidden;
        outline: 0;
        position: absolute;
        @include z-index(Dropdown);

        &--Fixed {
            position: fixed;
            left: auto;
        }
    }

    &__Panels {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(Surface) {
            flex-direction: row;
        }
    }

    &__Panel {
        background-color: var(--bd-Panel-Background);
        width: var(--bd-Panel-Width);
    }

    &__Header {
        align-items: center;
        border-bottom: 1px solid var(--bd-Panel-BorderColor);
        display: flex;
        justify-content: space-between;
        padding: 10px;
    }

    &__Switch {
        align-items: center;
        display: flex;

        > * {
            &:not(:last-child) {
                margin-right: 5px;
            }
        }
    }

    &__Body {
        padding: 10px;
        width: 100%;
    }

    &__Content {
        border-collapse: inherit;
        border-spacing: 0px;
        width: 100%;
    }

    &__Row {
        padding-bottom: 3px;
        padding-top: 3px;
    }

    &__Cell {
        background: var(--bd-Cell-Background);
        border-radius: .42rem;
        color: var(--bd-Cell-Color);
        cursor: pointer;
        height: 36px;
        font-size: .875rem;
        font-weight: 400;
        min-width: 24px;
        position: relative;
        transition: all .2s ease-out;

        &--Head {
            cursor: default;
            height: 30px;
            font-weight: 600;
            line-height: 30px;
            width: 36px;
            --bd-Cell-Color: #{map-deep-get($datepicker-settings, Cell, DefaultColor)};
        }

        &:hover {
            &:not(#{$datepicker}__Cell--Head) {
                background: var(--bd-Cell-Background);
                border-color: var(--bd-Cell-Background);
                --bd-Cell-Background: #{map-deep-get($datepicker-settings, Cell, HoverBackground)};
            }
        }

        &--InView {
            --bd-Cell-Color: #{map-deep-get($datepicker-settings, Cell, DefaultColor)};
        }

        &--Selected {
            --bd-Cell-Background: #{map-deep-get($datepicker-settings, Cell, SelectedBackground)};
            --bd-Cell-Color: #{map-deep-get($datepicker-settings, Cell, SelectedColor)};
        }

        &--RangeStart {
            --bd-Cell-Background: #{map-deep-get($datepicker-settings, Cell, RangeStartBackground)};
            --bd-Cell-Color: #{map-deep-get($datepicker-settings, Cell, RangeStartColor)};
        }

        &--InRange {
            --bd-Cell-Background: #{map-deep-get($datepicker-settings, Cell, InRangeBackground)};
            --bd-Cell-Color: #{map-deep-get($datepicker-settings, Cell, InRangeColor)};
        }

        &--RangeEnd-End {
            --bd-Cell-Background: #{map-deep-get($datepicker-settings, Cell, RangeEnd-EndBackground)};
            --bd-Cell-Color: #{map-deep-get($datepicker-settings, Cell, RangeEnd-EndColor)};
        }

        &--Today {
            border: 1px solid var(--bd-Cell-Color);
        }

        &--Disabled {
            pointer-events: none;
            --bd-Cell-Color: #{map-deep-get($datepicker-settings, Cell, DisabledColor)};
        }
    }


    &__Footer {
        align-items: center;
        border-top: 1px solid var(--bd-Panel-BorderColor);
        display: flex;
        justify-content: center;
        padding: 10px;
    }
}
