﻿@import "../../Styles/Base/Helpers";

$icon-settings: (
    Size: (
        ExtraSmall: (
            Size: .6rem,
        ),
        Small: (
            Size: 1rem,
        ),
        Medium: (
            Size: 1.5rem,
        ),
        Large: (
            Size: 2rem,
        ),
        ExtraLarge: (
            Size: 2.6rem,
        )
    ),
    Theme: (
        
    ),
);

@keyframes spinning {
    100% {
      transform: rotate(360deg);
    }
  }

.o-Icon {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    user-select: none;

    @each $size, $properties in map-get($icon-settings, Size) {
        &--#{$size} {
            height: map-get($properties, Size);
            width: map-get($properties, Size);
        }
    }

    &--Spinning {
        animation: spinning 1s infinite linear;
    }

    svg {
        display: inline-flex;
    }
}