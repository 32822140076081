@import "../../Styles/Base/Helpers";

$radio-settings: (
    Validation: (
        Invalid: palette(Danger),
        Valid: palette(Success)
    ),
    Radio: (
        Color: palette(Dark, Lighter-30),
        BorderColor: palette(Dark, Lighter-75),
        CircleColor: palette(Dark, Lighter-75),
        FontSize: .875rem,
        InnerSize: .875rem,
        OuterSize: 1.5rem
    ),
    State: (
        Checked: (
            BorderColor: palette(Primary),
            CircleColor: palette(Primary),
        ),
        Disabled: (
            Cursor: not-allowed,
            Color: palette(Dark, Lighter-50),
            BorderColor: palette(Dark, Lighter-80),
            CircleColor: palette(Dark, Lighter-80),
        ),      
    )
);

.c-RadioGroup {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    > .o-Radio {
        &:not(:last-child) {
            margin-bottom: .5rem;
        }
    }
}

.o-Radio {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin: 0;
    position: relative;
    text-align: left;
    transition: all 0.3s ease;

    $radio: &;

    --bd-Radio-BorderColor: #{map-deep-get($radio-settings, Radio, BorderColor)};
    --bd-Radio-CircleColor: #{map-deep-get($radio-settings, Radio, CircleColor)};
    --bd-Radio-Color: #{map-deep-get($radio-settings, Radio, Color)};
    --bd-Radio-FontSize: #{map-deep-get($radio-settings, Radio, FontSize)};
    --bd-Radio-InnerSize: #{map-deep-get($radio-settings, Radio, InnerSize)};
    --bd-Radio-OuterSize: #{map-deep-get($radio-settings, Radio, OuterSize)};

    @each $state, $properties in map-get($radio-settings, State) {
        &--#{$state} {
            @if $state == Disabled {
                cursor: map-get($properties, Cursor);
                --bd-Radio-Color: #{map-get($properties, Color)};
            }

            #{$radio}__Circle {
                @if $state == Checked {
                    &:after {
                        opacity: 1;
                    }
                }
                --bd-Radio-BorderColor: #{map-get($properties, BorderColor)};
                --bd-Radio-CircleColor: #{map-get($properties, CircleColor)};
            }
        }
    }

    &:hover:not(#{$radio}--Disabled) {
        $hover-state: map-deep-get($radio-settings, State, Checked);

        #{$radio}__Circle {
            --bd-Radio-BorderColor: #{map-get($hover-state, BorderColor)};
        }
    }

    &__NativeControl {
        opacity: 0;
        position: absolute;
        z-index: -1;
    }

    &__Circle {
        align-items: center;
        border: 2px solid var(--bd-Radio-BorderColor);
        border-radius: 50%;
        display: flex;
        flex-shrink: 0;
        height: var(--bd-Radio-OuterSize);
        justify-content: center;
        margin-right: 0.75rem;
        transition: all .2s ease;
        width: var(--bd-Radio-OuterSize);    

        &:after {
            background: var(--bd-Radio-CircleColor);
            border-radius: 50%;
            content: '';
            height: var(--bd-Radio-InnerSize);
            opacity: 0;
            transition: all .2s ease;
            width: var(--bd-Radio-InnerSize);
        }
    }

    &__Label {
        color: var(--bd-Radio-Color);
        font-size: var(--bd-Radio-FontSize);
    }
}