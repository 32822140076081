@import "../../Styles/Base/Helpers";

$label-settings: (
    Color: palette(Dark, Lighter-30),
    FontSize: .75rem,
);

.o-Label {
    color: map-get($label-settings, Color);
    display: block;
    font-size: map-get($label-settings, FontSize);
    margin-bottom: .25rem;
}