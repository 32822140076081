@import "../../Styles/Base/Helpers";

$badge-settings: ( Size: ( Small: 10px, Medium: 12px, Big: 14px ), Theme: ( Primary: ( Background: palette(Primary), Color: palette(Neutral, White) ), Secondary: ( Background: palette(Secondary), Color: palette(Neutral, White) ), Information: ( Background: palette(Information), Color: palette(Neutral, White) ), Success: ( Background: palette(Success), Color: palette(Neutral, White) ), Warning: ( Background: palette(Warning), Color: palette(Neutral, White) ), Danger: ( Background: palette(Danger), Color: palette(Neutral, White) ), Light: ( Background: palette(Dark, Lighter-80), Color: palette(Dark, Lighter-30) ), Dark: ( Background: palette(Dark), Color: palette(Neutral, White) ), White: ( Background: palette(Neutral, White), Color: palette(Dark, Lighter-30) ), Layout: (Background: palette(Primary, Base, 1, Hoteldoor), Color: palette(Neutral, White)) ), Variant: ( Rounded: .5em, Pill: 50em, ), FontWeight: 400, LetterSpacing: .05em, PaddingX: .7em, PaddingY: .525em, Shadow: 0 0 4px 0 rgba(0,0,0,0.21) );


.o-Badge {
    align-items: center;
    display: inline-flex;
    font-weight: var(--bd-Badge-FontWeight);
    justify-content: center;
    letter-spacing: map-get($badge-settings, LetterSpacing);
    line-height: 1;
    padding-bottom: map-get($badge-settings, PaddingY);
    padding-left: map-get($badge-settings, PaddingX);
    padding-right: map-get($badge-settings, PaddingX);
    padding-top: map-get($badge-settings, PaddingY);
    user-select: none;
    white-space: nowrap;
    --bd-Badge-FontWeight: #{map-get($badge-settings, FontWeight)};

    &--Shadow {
        box-shadow: #{map-get($badge-settings, Shadow)};
    }

    &:empty {
        display: none;
    }

    @each $size, $fontsize in map-get($badge-settings, Size) {
        &--#{$size} {
            font-size: $fontsize;
        }
    }

    @each $theme, $colors in map-get($badge-settings, Theme) {
        &--#{"" + $theme} {
            --bd-Badge-Background: #{map-get($colors, Background)};
            background-color: var(--bd-Badge-Background);
            color: map-get($colors, Color);
        }
    }

    @each $variant, $radius in map-get($badge-settings, Variant) {
        &--#{$variant} {
            border-radius: $radius;

            @if $variant == Pill {
                --bd-Badge-FontWeight: 700;
            }
        }
    }
}
