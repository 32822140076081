﻿@import "../../Styles/Base/Helpers";
@import '@glidejs/glide/src/assets/sass/glide.core';

$carousel-settings: ( 
    Primary: ( 
        Arrows: ( 
            Background: palette(Primary, Base), 
            Color: palette(Neutral, White), 
            Height: 40px, Width: 40px 
        ), 
        Bullets: ( 
            Solid: ( 
                Border: none, 
                Border-Active: none, 
                Main: palette(Neutral, White), 
                Active: palette(Secondary, Base), 
                Main-Size: 8px, Active-Size: 10px 
            ), 
            Outline: ( 
                Border: 1px solid palette(Neutral, White),
                Border-Active: 1px solid transparent, 
                Main: palette(Secondary, Base), 
                Active: palette(Neutral, White), 
                Main-Size: 10px, Active-Size: 7px 
            ) 
        ), 
        Buttons: (
            Label-Inactive: palette(Dark, Lighter-30)
        ),
    ), 
    Secondary: ( 
        Arrows: ( 
            Background: palette(Primary, Base), 
            Color: palette(Neutral, White), 
            Height: 40px, Width: 40px 
        ), 
        Bullets: ( 
            Solid: ( 
                Border: none, 
                Border-Active: none, 
                Main: palette(Dark, Lighter-70), 
                Active: palette(Secondary, Base), 
                Main-Size: 8px, Active-Size: 10px 
            ), 
            Outline: ( 
                Border: 1px solid palette(Secondary, Base), 
                Border-Active: 1px solid transparent, 
                Main: palette(Neutral, White), 
                Active: palette(Secondary, Base),
                Main-Size: 10px, Active-Size: 7px 
            )
        ),
        Buttons: (
            Label-Inactive: palette(Dark, Lighter-30)
        ),
    ),
);

.glide {
    $glide: &;

    &__slide {
        height: auto;
        width: auto;
    }

    &--slideshow {
        #{$glide}__slides {
            transform: none !important;
            width: auto !important;
            display: block;
        }

        #{$glide}__slide {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            transition: opacity 1s;

            &--active {
                z-index: 1;
                opacity: 1;
            }

            &:first-child {
                position: relative;
            }
        }
    }

    &__controls {
        display: flex;
    }

    &__arrows {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    &__arrow {
        align-items: center;
        border: 0;
        display: inline-flex;
        justify-content: center;
        outline: none;
        padding: 0;
        transition: all .3s ease-out;
    }

    &__bullets {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        min-height: 10px;
    }

    &__bullet {
        border: none;
        border-radius: 100%;
        cursor: pointer;
        margin: 0 .25rem 0;
        outline: none;
        padding: 0;
        transition: all .3s ease-in-out;
    }
}

.c-Carousel {
    $carousel: &;

    @each $modifier, $skin in $carousel-settings {
        &--#{$modifier} {
            #{$carousel}__Arrow {
                --bd-Arrow-Background: #{map-deep-get($skin, Arrows, Background)};
                --bd-Arrow-Color: #{map-deep-get($skin, Arrows, Color)};
                background-color: var(--bd-Arrow-Background);
                border-radius: 50%;
                color: var(--bd-Arrow-Color);
                height: map-deep-get($skin, Arrows, Height);
                width: map-deep-get($skin, Arrows, Width);

                &:hover {
                    opacity: .8;
                }
            }

            #{$carousel}__Buttons {

                @include media-breakpoint-down(Phablet) {
                    display: none;
                }

                width: fit-content;
                margin: auto;

                .glide__control {

                    &.o-Button--SpeechBubble {
                        .o-Button__Label {
                            line-height: normal;
                        }

                        &:not(.glide__bullet--active) {
                            background-color: transparent;
                            box-shadow: none;
                            border-radius: 0px;
                            border-top: 1px solid #E1E1E1;
                            border-bottom: 1px solid #E1E1E1;
                            border-left: 1px solid #E1E1E1;

                            &:first-child {
                                border-top-left-radius: 8px;
                                border-bottom-left-radius: 8px;
                            }

                            &:last-child {
                                border-right: 1px solid #E1E1E1;
                                border-top-right-radius: 8px;
                                border-bottom-right-radius: 8px;
                            }

                            .o-Button__Label {
                                font-weight: 400;

                                #{$carousel}__Button__Label {
                                    --bd-Inactive-Color: #{map-deep-get($skin, Buttons, Label-Inactive)};
                                    color: var(--bd-Inactive-Color);
                                }

                                #{$carousel}__Button__Description {
                                    opacity: 0.5;
                                }
                            }

                            &::after {
                                content: none;
                            }
                        }
                    }
                }
            }

            @each $style, $colors in map-get($skin, Bullets) {
                &.c-Carousel--#{$style} {
                    .c-Carousel__Bullets {
                        .glide__bullet {
                            --bd-Bullet-Background: #{map-get($colors, Main)};
                            --bd-Bullet-Border: #{map-get($colors, Border)};
                            --bd-Bullet-Size: #{map-get($colors, Main-Size)};
                            background-color: var(--bd-Bullet-Background);
                            border: var(--bd-Bullet-Border);
                            height: var(--bd-Bullet-Size);
                            width: var(--bd-Bullet-Size);

                            &--active {
                                --bd-Bullet-Active-Background: #{map-get($colors, Active)};
                                --bd-Bullet-Active-Border: #{map-get($colors, Border-Active)};
                                --bd-Bullet-Size: #{map-get($colors, Active-Size)};
                                background-color: var(--bd-Bullet-Active-Background);
                                border: var(--bd-Bullet-Active-Border);
                            }
                        }
                    }
                }
            }
        }
    }

    &--FitParent {
        height: 100%;

        #{$carousel}__Track {
            height: 100%;
        }

        #{$carousel}__Slides {
            height: 100%;
        }

        #{$carousel}__Slide {
            height: 100%;
        }
    }

    &--SlidingEffect {
        #{$carousel}__Slide {
            .o-Multimedia__Source {
                transform: scale(1.3);
                transition: transform 5s ease-out;
            }

            &.glide__slide--active {
                .o-Multimedia__Source {
                    transform: translateX(-10%) scale(1.3);
                }
            }
        }
    }
}
