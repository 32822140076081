@import "../../Styles/Base/Helpers";

$fieldset-settings: ( Background: palette(Neutral, White), BorderRadius: var(--bd-Card-Radius), BorderColor: palette(Dark, Lighter-75) );

.c-Fieldset {
    flex-direction: column;
    min-width: 0;
    position: relative;
    word-wrap: break-word;
    --bd-Fieldset-BorderColor: #{map-get($fieldset-settings, BorderColor)};
    border: 2px solid var(--bd-Fieldset-BorderColor);
    border-radius: map-get($fieldset-settings, BorderRadius);

    $fieldset: &;

    &__Badge {
        position:relative;
        top: -1em;
        left: 0.5em;
    }

    &__Content {
        padding-bottom: 1.5rem;
    }
}
